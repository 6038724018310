<template>
    <div class="custom-header-container" v-if="!loading && shop.id">
        <div class="custom-header-top">
            <v-img v-if="shop.photo.large" :src="shop.photo.large" class="custom-header-bg">
                <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
                </template>
            </v-img>
            <v-img v-if="!shop.photo.large && shopCategory.photo.large" :src="shopCategory.photo.large" class="custom-header-bg">
                <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
                </template>
            </v-img>
            <div class="custom-header-logo-container d-none">
                <v-img v-if="shop.logo.square" :src="shop.logo.square" class="custom-header-logo">
                    <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
                    </template>
                </v-img>
                <v-img v-if="!shop.logo.square && shopCategory.photo.square" :src="shopCategory.photo.square" class="custom-header-logo">
                    <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
                    </template>
                </v-img>
                <div class="custom-header-logo" v-if="!shop.logo.square && !shopCategory.photo.square">
                    {{ shop.name.split(' ')[0].charAt(0) }}{{ shop.name.split(' ')[1].charAt(0) }}
                </div>
            </div>
            <v-chip small color="white" class="custom-header-category" v-if="shopCategory.name">
                <v-icon small color="amber" class="me-1" left>bookmark</v-icon>{{ shopCategory.name }}
            </v-chip>
            <ShopLikeButton :shopId="shop.id" color="pink" xSmall fab dark />
        </div>
        <div class="custom-header-content container mb-3">
            <div class="custom-header-page-name">{{ shop.name }}
            <v-btn v-if="shop.latitude && shop.longitude" :href="'https://www.openstreetmap.org/?mlat=' + shop.latitude + '&mlon=' + shop.longitude + '&z=18'" target="_blank" title="ouvre une nouvelle fenêtre" color="primary" elevation="1" class="ms-2 float-right" fab small @click.stop=""><v-icon>mdi-directions</v-icon></v-btn></div>
            <div class="custom-header-infos">
                <div class="desc mb-2">
                    <span class="text">{{ shop.description }}</span>
                </div>
                <v-divider class="my-2"></v-divider>
                <div class="hours" v-if="hours.length > 0">
                    <v-icon small color="amber" class="me-1" left>mdi-clock</v-icon>
                    <span class="text">Horaires :</span>
                    <ul class="hour" v-for="hour in hours" :key="hour.day">
                        <li class="text">{{ dayFormat(hour.day) }} : de {{ hourFormat(hour.open_at) }} à {{ hourFormat(hour.close_at) }}</li>
                    </ul>
                    <v-divider class="my-2"></v-divider>
                </div>
                <div class="address mb-2" v-if="shop.address">
                    <v-icon small color="amber" class="me-1" left>mdi-map-marker</v-icon>
                    <span class="text">{{ shop.address }}</span>
                </div>
                <div class="phone mb-2" v-if="shop.phone">
                    <v-icon small color="amber" class="me-1" left>mdi-phone</v-icon>
                    <span class="text">{{ shop.phone }}</span>
                </div>
                <div class="email mb-2" v-if="shop.email">
                    <v-icon small color="amber" class="me-1" left>mdi-email</v-icon>
                    <span class="text">{{ shop.email }}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { DEBUG } from "@/main";
import { ShopService } from "@/services/shop-services";
import { useFavStore } from "@/stores/favorites-store";
import { useNavStore } from "@/stores/navigation-store";
import { useShopStore } from "@/stores/shop-store";
import { dayFormat, hourFormat } from "@/utils";
import ShopLikeButton from "./ShopLikeButton.vue";


const shopService = new ShopService()

export default {
    name: "ShopHeader",
    data() {
        return {
            loading: true,
            shop: {},
            shopCategory: null,
            hours: []
        };
    },
    methods: {
        addToFav() {
            let added = this.favStore.addFav(this.shopId)
            if (added) {
                console.log("Ajouté aux favoris")
            }
            else {
                this.$toast.error("Impossible de l'ajouté dans les favoris")
            }
        },
        dayFormat(day) {
            return dayFormat(day)
        },
        hourFormat(hour) {
            return hourFormat(hour)
        },
        async getHours() {
            try {
                const hours = await shopService.getShopHoraire(this.shop.id)
                if (hours) {
                    return hours
                }
            }
            catch (err) {
                if (DEBUG) {
                    console.error(err)
                }
            }
        }
    },
    beforeMount() {
        this.shop = this.shopStore.getShopById(this.shopId)
        this.shopCategory = this.shopStore.getCategoryById(this.shop.category)
        this.shopStore.setShop(this.shop)
        this.navStore.setPageTitle(this.shop.name)
        this.getHours().then(hours => {
            this.hours = hours
        })
        this.loading = false
        this.navStore.pageLoading = false
    },
    setup() {
        const shopStore = useShopStore()
        const favStore = useFavStore()
        const navStore = useNavStore()
        return {
            shopStore,
            favStore,
            navStore,
        };
    },
    computed: {
        shopId() {
            return this.$route.params.id
        }
    },
    components: { ShopLikeButton }
}
</script>