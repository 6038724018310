<template>
    <div class="cart-line">
        <div v-if="loading" style="height:100px">
            <v-row class="fill-height text-center" align-content="center" justify="center">
                <v-col cols=12>
                    <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
            </v-row>
        </div>
        <div class="cart-line-container" v-if="!loading">
            <div class="cart-line-img-container">
                <v-img v-if="product.photo.square" :src="product.photo.square" class="cart-line-img">
                    <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
                    </template>
                </v-img>
            </div>
            <div class="cart-line-content">
                <div class="cart-line-content-top">
                    <div class="cart-line-title">{{ product.name }}</div>
                    <div class="cart-line-desc d-none" v-if="product.description">{{ couper(product.description, 70) }}</div>
                    <div class="cart-line-infos">
                        <div v-if="product.stock > 0 && product.stock <= 10" class="stock text-warning">
                            Plus que {{ product.stock }}
                        </div>
                        <span v-if="product.stock <= 0" class="stock empty text-danger">
                            <v-icon color="danger" x-small>inventory</v-icon> Indisponible
                        </span>
                    </div>
                </div>
                <div class="cart-line-buttons">
                    <v-btn outlined fab x-small color="primary" :disabled="item.quantity == 1" @click.prevent="decrease"><v-icon>mdi-minus</v-icon></v-btn>
                    <div class="cart-line-quantity">{{ item.quantity }}</div>
                    <v-btn outlined fab x-small color="primary" @click.prevent="increase"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-btn class="ms-2" elevation="0" fab x-small color="dark" @click.prevent="removeItem"><v-icon>delete</v-icon></v-btn>
                </div>
                <div class="cart-line-price"><span class="exergue">{{ formatPrice(item.unit_price_ttc*item.quantity) }}</span>€</div>
            </div>
        </div>
    </div>
</template>

<script>
import { ProductService } from "@/services/product-services";
import { useCartStore } from "@/stores/cart-store";
import { formatPrice } from "@/utils";

const productService = new ProductService();

export default {
    name: "Cart",
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            product: {},
            loading: true
        }
    },
    methods: {
        async getProduct() {
            const product = await productService.getProduct(this.item.product)
            if (product) {
                return product
            }
            return []
        },
        formatPrice(price) {
            return formatPrice(price)
        },
        couper(text, limit) {
            return text.length > limit ? text.substring(0,limit)+'...' : text
        },
        updateItem() {
            let isUpdated = this.cartStore.updateCartItem(this.item)
            if (isUpdated) {
                console.log('Mise à jour réussie !')
                this.$emit('update')
            } else {
                this.$toast.error('Une erreur est survenue')
            }
        },
        removeItem() {
            let isRemoved = this.cartStore.removeCartItem(this.item)
            if (isRemoved) {
                console.log('Suppression réussie !')
                this.$emit('update')
            } else {
                this.$toast.error('Une erreur est survenue')
            }
        },
        increase() {
            this.item.quantity++
            this.updateItem(this.item)
        },
        decrease() {
            this.item.quantity--
            this.updateItem(this.item)
        },
    },
    beforeMount() {
        this.getProduct().then((product) => {
            this.product = product
            this.loading = false
        })
    },
    setup() {
        const cartStore = useCartStore()
        return {
            cartStore,
        }
    }
}

</script>