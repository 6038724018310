import { getCookie, setCookie } from "@/utils";
import { defineStore } from "pinia";

export const useFavStore = defineStore('favStore', {
    state: () => {
        return {
            favorites: [],
        }
    },
    actions: {
        initFavList() {
            const favCookie = JSON.parse(getCookie('clickncollect-favorites'))
            if (favCookie) {
                this.favorites = favCookie
            }
        },
        /**
         * Ajouter le shop dans les favoris
         * @param {Integer} shopId 
         */
        addFav(shopId) {
            const shopIndex = this.favorites.findIndex(id => id == shopId)
            if (shopIndex == -1) {
                this.favorites.push(shopId)
                setCookie('clickncollect-favorites', JSON.stringify(this.favorites), 90)
                return true
            }
            return false
        },
        /**
         * Retirer le shop dans les favoris
         * @param {Integer} shopId 
         */
        removeFav(shopId) {
            const shopIndex = this.favorites.findIndex(id => id == shopId)
            if (shopIndex > -1) {
                this.favorites.splice(shopIndex,1)
                setCookie('clickncollect-favorites', JSON.stringify(this.favorites), 90)
                return true
            }
            return false
        },
    },
})