<template>
    <div class="product-bottom-bloc" v-if="product.id">
        <div class="d-flex align-items-center justify-space-between pb-3">
            <div class="product-price">
                <span class="text-small d-block">Prix</span>
                <span class="exergue">{{ formatPrice(product.price_ttc * amount) }}</span>€
            </div>
            <div class="product-amount-buttons">
                <v-btn class="mx-2" outlined fab small color="primary" :disabled="amount == 1" @click.prevent="amount--"><v-icon>mdi-minus</v-icon></v-btn>
                <div class="amount-value">{{ amount }}</div>
                <v-btn class="mx-2" outlined fab small color="primary" @click.prevent="amount++"><v-icon>mdi-plus</v-icon></v-btn>
            </div>
        </div>
        <v-btn block color="primary" @click.prevent="addToCart">Ajouter au panier</v-btn>
    </div>
</template>

<script>
import { useAuthUser } from "@/stores/auth-store";
import { useCartStore } from "@/stores/cart-store";
import { formatPrice } from "@/utils";

export default {
    name: 'ProductOrderButtons',
    props: {
        product: {
            type: Object,
            required: true,
        },
        productCategory: {
            type: Object,
        },
    },
    data() {
        return {
            loading: true,
            amount: 1,
        }
    },
    methods: {
        formatPrice(price) {
            return formatPrice(price);
        },
        addToCart() {
            let shop = this.product.store
            let id = this.product.id
            let quantity = this.amount
            let unit_price_ttc = this.product.price_ttc
            let item = {shop: shop, product: id, quantity: quantity, unit_price_ttc: unit_price_ttc}
            let itemAdded = this.cartStore.addToCart(item)
            if (itemAdded) {
                this.$toast.success('Ajouté au panier')
            } else {
                this.$toast.error('Une erreur est survenue')
            }
        }
    },
    setup() {
        const authUser = useAuthUser();
        const cartStore = useCartStore();
        return {
            authUser,
            cartStore,
        }
    }
}
</script>