<template>
    <div class="favorites-page">
        <v-container v-if="loading" style="height:300px">
            <v-row class="fill-height text-center" align-content="center" justify="center">
                <v-col cols=12>
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        :size="70"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-container>
        <div class="favorites" v-if="!loading && shops.length > 0">
            <div class="d-block mb-5" v-for="shop in shops" :key="shop">
                <ShopCard :longAdress="false" :shop="shopStore.shopList.filter(store => store.id == shop)[0]" />
            </div>
        </div>
        <div class="product-card no-btn" v-if="!loading && shops.length == 0">
            <div class="product-card-content text-center">
                <div class="product-card-text">
                <div class="mb-2">
                    <v-icon color="primary" x-large>mdi-heart-broken</v-icon>
                </div>
                <strong>Aucun favoris</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss"></style>

<script>
import { useFavStore } from "@/stores/favorites-store";
import { useNavStore } from "@/stores/navigation-store";
import { useShopStore } from "@/stores/shop-store";
import ShopCard from "@/views/shop/components/ShopCard.vue";


export default {
    name: "FavoritesPage",
    components: { ShopCard },
    data : () => ({
        loading: true,
        shops: [],
    }),
    beforeMount() {
        this.navStore.setPageTitle('Mes favoris')
        this.favStore.$subscribe((callback, state) => {
            this.shops = state.favorites
        })
        this.shops = this.favStore.favorites
        this.loading = false
        this.navStore.pageLoading = false
    },
    setup() {
        const shopStore = useShopStore()
        const favStore = useFavStore()
        const navStore = useNavStore()
        return {
            shopStore,
            favStore,
            navStore,
        }
    }
};
</script>
