<template>
    <v-row>
        <v-col cols="12">
            <v-container v-if="loading" style="height:300px">
                <v-row class="fill-height text-center" align-content="center" justify="center">
                    <v-col cols=12>
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            :size="70"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-container>
            <v-row v-if="!loading  && shops.length">
                <v-col cols="12" md="6" lg="4" xl="3" v-for="shop in shops" :key="'shop-'+shop.id">
                    <ShopCard :shop="shop"/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style lang="scss"></style>

<script>

import ShopCard from "@/views/shop/components/ShopCard.vue";
import { useShopStore } from "@/stores/shop-store";
import { ShopService } from "@/services/shop-services";
import { navigate, shuffleArray } from "@/utils";
import { useNavStore } from "@/stores/navigation-store";

const shopService = new ShopService();

export default {
    name: "CategoryPage",
    components: {
        ShopCard,
    },
    data: () => ({
        loading: true,
        shops: null,
    }),
    methods: {
        async getShops() {
            const res = await shopService.getShopsByCategory(this.categoryId);
            if (!res.error) {
                this.shops = res;
            } else {
                console.log("Oops impossible de charger les commerçants de cette catégorie")
            }
        }
    },
    beforeMount() {
        var category = this.shopStore.getCategoryById(this.categoryId)
        if (this.categoryId && category.length != 0) {
            this.shopStore.setSelectedCategory(category);
            this.navStore.setPageTitle(category.name);
            this.shops = shuffleArray(this.shopStore.shopList.filter(shop => shop.category == this.categoryId))
            this.loading = false
        } else {
            this.navStore.setPageTitle('Erreur 404')
            this.loading = false
            setTimeout(() => {
                this.navStore.pageLoading = true
                this.navStore.setPageTitle('')
                navigate(this.$router,{name: 'home'}).then(() => {
                    this.navStore.pageLoading = false
                })
            }, 1000)
        }
    },
    setup() {
        const shopStore = useShopStore();
        const navStore = useNavStore()
        return {
            shopStore,
            navStore,
        };
    },
    computed: {
        categoryId () {
            return this.$route.params.id
        },
    },
};
</script>