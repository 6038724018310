<template>
    <div class="profile-page">
        <div class="profile-card" v-if="Object.keys(authUser.user).length != 0">
            <div class="profile-card-initials">
                {{ authUser.user.last_name.charAt(0) }}{{ authUser.user.first_name.charAt(0) }}
            </div>
            <div class="profile-card-infos">
                <div class="name">{{ authUser.user.last_name }} {{ authUser.user.first_name }}</div>
                <div class="email">{{ authUser.user.email }}</div>
            </div>
            
            <v-btn class="ms-0 me-auto mb-3" elevation=0 small @click="edit = !edit" v-if="edit"><v-icon left>mdi-arrow-left</v-icon> Retour</v-btn>

            <div class="profile-tabs" v-if="edit">
                <template>
                    <v-tabs v-model="tab" grow icons-and-text background-color="blue-grey lighten-5">
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab href="#tab-1">
                            Identité
                            <v-icon>mdi-card-account-details-outline</v-icon>
                        </v-tab>
                        <v-tab href="#tab-2">
                            Emails
                            <v-icon>email</v-icon>
                        </v-tab>
                        <v-tab href="#tab-3">
                            Mot de passe
                            <v-icon>lock</v-icon>
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab" class="py-2">
                        <v-tab-item id="tab-1"><ProfileUser /></v-tab-item>
                        <v-tab-item id="tab-2"><ProfileEmails /></v-tab-item>
                        <v-tab-item id="tab-3"><ProfilePassword /></v-tab-item>
                    </v-tabs-items>
                </template>
            </div>
            <div class="profile-card-menu" v-if="!edit">
                <v-list>
                    <v-list-item-group>
                        <v-list-item v-for="(item, i) in menu" :key="i" @click.prevent="item.action">
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </div>
        </div>
    </div>
</template>

<script>
import { useAuthUser } from "@/stores/auth-store"
import ProfileUser from "@/views/profile/components/ProfileUser.vue"
import ProfileEmails from "@/views/profile/components/ProfileEmails.vue";
import ProfilePassword from "@/views/profile/components/ProfilePassword.vue";
import { navigate } from "@/utils";
import { AuthenticationService } from "@/services/auth-services";
import { useNavStore } from "@/stores/navigation-store";

const authService = new AuthenticationService;

export default {
    name: "ProfilePage",
    data: () => ({
        user: {},
        menu: [],
        edit: false,
        tab: null,
    }),
    methods: {
        async logOut() {
            this.$toast.info("Déconnection en cours");
            try {
                await authService.logOut(this.authUser.getToken);
                this.authUser.clearAuth();
                this.$router.push({
                    name: "login",
                });
            }
            catch {
                this.$toast.error("Une erreur s'est produite lors de votre déconnection. Veuillez contacter un administrateur.");
            }
        },
        navigate(routerName) {
            if (this.navStore.getSelectedMenu != routerName) {
                this.navStore.pageLoading = true
                navigate(this.$router, { name: routerName }).then(() => {
                    this.navStore.pageLoading = false
                });
            }
        },
    },
    beforeMount() {
        this.user = this.authUser.user;
        this.navStore.setPageTitle('Mon compte');
        this.menu = [
            {text: 'Modifier mes informations', icon: 'mdi-cog', action: () => {this.edit = !this.edit}},
            {text: 'Mes commandes', icon: 'shopping_basket', action: () => {this.navigate('orders')}},
            {text: 'Déconnexion', icon: 'mdi-logout-variant', action: () => {this.logOut()}},
        ]
    },
    setup() {
        const authUser = useAuthUser();
        const navStore = useNavStore()
        return {
            authUser,
            navStore,
        };
    },
    components: { ProfileUser, ProfileEmails, ProfilePassword }
}
</script>