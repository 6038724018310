import { defineStore } from 'pinia'


export const useShopStore = defineStore('shopStore', {
    state: () => {
        return {
            shop: {},
            shopList: [],
            category: null,
            categories: [],
            hours: [],
            productCategories: [],
            // utilisé pour forcer une resouscription au store
            // pratique pour les modifications de produit ou de facture
            refresher: null
        }
    },
    actions: {
        setShop(shop) {
            this.shop = shop;
        },
        setShopList(shopList) {
            this.shopList = shopList;
        },
        setRefresher(value) {
            const nowTime = new Date().getTime()
            this.refresher = `${value}-${nowTime}`
        },
        setSelectedCategory(cat) {
            this.category = cat;
        },
        
    },
    getters: {
        getCategoryById: (state) => {
            return (catId) => state.categories.find(cat => cat.id == catId)
        },
        getShopById: (state) => {
            return (shopId) => state.shopList.find(shop => shop.id == shopId)
        },
        getProductCategoryById: (state) => {
            return (catId) => state.productCategories.find((cat) => cat.id === catId)
        },
    }
})