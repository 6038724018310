<template>
  <div class="login-page">
    <PageLoader />
    <div class="login-image" v-if="!mobileView">
      <div class="deco-float deco-float-1 rotate-x--5 opacity-25">
        <div class="login-deco animate-move-up-down">
          <img src="@/assets/actshop1.jpg" alt />
        </div>
      </div>
      <div class="deco-float deco-float-2 rotate-x--5 opacity-10">
        <div class="login-deco animate-move-up-down">
          <img src="@/assets/actshop2.jpg" alt />
        </div>
      </div>
      <div class="login-image-container">
        <div class="brand-card rotate-x-5">
          <img src="@/assets/clicklogo.jpg" alt class="brand-card-img" />
          <div class="brand-card-text text-center">
            <h1 class="brand-card-text-title">Click N'Collect</h1>
            <div class="brand-card-text-info">Ville de Sainte-Anne</div>
          </div>
        </div>

        <div class="shopping-card rotate-x--2 opacity-75">
          <img src="@/assets/shop1.jpg" class="shopping-card-img" alt />
          <div class="shopping-card-content">
            <div class="shopping-card-content-top">
              <div class="shopping-card-content-title">La pause café</div>
              <div class="shopping-card-content-btn">
                <v-icon color="white">mdi-directions</v-icon>
              </div>
            </div>
            <p class="mb-0">Un souffle de bien-être, des arômes légères</p>
            <div class="shopping-card-content-info">
              <div class="shopping-card-content-info-value">
                5 Immeuble Belize, Boulevard des...
              </div>
            </div>
          </div>
        </div>

        <div class="shopping-card rotate-x-5 opacity-50">
          <img src="@/assets/shop2.jpg" class="shopping-card-img" alt />
          <div class="shopping-card-content">
            <div class="shopping-card-content-top">
              <div class="shopping-card-content-title">Le bon pain</div>
              <div class="shopping-card-content-btn">
                <v-icon color="white">mdi-directions</v-icon>
              </div>
            </div>
            <p class="mb-0">Le rendez-vous du matin et des petites fringales</p>
            <div class="shopping-card-content-info">
              <div class="shopping-card-content-info-value">
                18 Avenue Charles de Gaulle...
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-form">
      <div class="logo-client">
        <img src="@/assets/client.jpg" alt />
      </div>
      <LoginForm />
      <Footer v-if="!mobileView" />
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import LoginForm from '@/views/login/components/LoginForm.vue';
import Footer from '@/components/Footer.vue';
import PageLoader from '@/components/PageLoader.vue';
import { useNavStore } from '@/stores/navigation-store';

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
    Footer,
    PageLoader
  },
  computed: {
    mobileView() {
        switch (this.$vuetify.breakpoint.name) {
            case "md": return true;
            case "sm": return true;
            case "xs": return true;
        }
        return false;
    }
  },
  setup() {
    const navStore = useNavStore()
    return { navStore }
  },
  beforeMount() {
    this.pageLoading = false
  }
}
</script>
