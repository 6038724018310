<template>
    <div class="profile-user-infos">
        <v-form ref="userForm" lazy-validation v-if="edit">
            <div  @keyup.enter="save">
                <v-text-field
                    v-model="user.last_name"
                    label="Nom"
                    filled
                    :error-messages="errors.last_name"
                ></v-text-field>
                <v-text-field
                    v-model="user.first_name"
                    label="Prénom"
                    filled
                    :error-messages="errors.first_name"
                ></v-text-field>
            </div>
            <v-btn
                color="primary"
                :loading="loading"
                @click.prevent="save()"
            >
                Enregistrer
            </v-btn>
        </v-form>   
    </div>
</template>

<script>
import { AuthenticationService } from "@/services/auth-services"
import { useAuthUser } from "@/stores/auth-store"

const authService = new AuthenticationService()

export default {
    name: 'ProfileUser',
    data: () => ({
        loading: false,
        errors: {},
        user: {
            first_name: '',
            last_name: ''
        },
        edit: true
    }),
    methods: {
        async save() {
            this.errors = {}
            if(this.$refs.userForm.validate()) {
                this.loading = true
                try {
                    const data = await authService.updateUser(this.user, this.authUser.getToken)
                    this.authUser.user = data
                    this.$toast.success(`Vos informations ont bien été mises à jour`)
                } catch(err) {
                    this.errors = err
                    if(err.non_field_errors) {
                        for (const message of err.non_field_errors) {
                        this.$toast.error(message)
                        }
                    } else {
                        this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
                    }
                } finally {
                    this.loading = false
                }
            }
        }
    },
    beforeMount() {
        const user = this.authUser.user
        this.user = {
            first_name: user.first_name,
            last_name: user.last_name,
        }
    },
    setup() {
        const authUser = useAuthUser()
        
        return {
            authUser,
        }
    }
}
</script>