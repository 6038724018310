<template>
<div class="page-loading" v-if="pageLoading">
    <div class="page-loading-content">
    <v-icon
        x-large
    >hourglass_empty</v-icon>
    Click N'Collect
    <v-progress-linear
        class="mt-2"
        indeterminate
        color="primary"
    ></v-progress-linear>
    </div>
</div>
</template>

<style lang="scss"></style>

<script>
import { useNavStore } from "@/stores/navigation-store"

export default {
    name: 'PageLoader',
    data: () => ({
        pageLoading: false
    }),
    beforeMount() {
        this.navStore.$subscribe((callback, state) => {
            this.pageLoading = state.pageLoading
        })
    },
    setup() {
        const navStore = useNavStore()
        return {
            navStore
        }
    }
}
</script>