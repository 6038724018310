<template>
  <div class="shop-card" v-if="shop" @click="navigate()">
    <div class="shop-card-top" v-if="shopCategory">
      <v-chip class="shop-card-top-tag" color="green" text-color="white" v-if="isOpen == true">Ouvert</v-chip>
      <v-chip class="shop-card-top-tag" color="red" text-color="white" v-if="isOpen == false">Fermé</v-chip>
      <v-img v-if="shop.photo.large" :src="shop.photo.large" class="shop-card-image">
        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
        </template>
      </v-img>
      <v-img v-if="!shop.photo.large && shopCategory.photo.large" :src="shopCategory.photo.large" class="shop-card-image">
        <template v-slot:placeholder>
          <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
        </template>
      </v-img>
      <ShopLikeButton :shopId="shop.id" color="pink" xSmall fab dark />
    </div>
    <div class="shop-card-content">
      <v-row>
        <v-col cols="9">
          <div class="shop-card-title">{{ shop.name }}</div>
          <div class="shop-card-text" :class="[ longAdress ? 'multiline-text' : 'text-truncate']" v-if="shop.description">{{ shop.description }}</div>
          <div class="shop-card-text" :class="[ longAdress ? 'multiline-text' : 'text-truncate']">
            <v-icon color="primary">map-marker</v-icon>
            {{ shop.address }}
          </div>
        </v-col>
        <v-col cols="3" class="shop-card-buttons">
          <v-btn v-if="shop.latitude && shop.longitude" :href="'https://www.openstreetmap.org/?mlat=' + shop.latitude + '&mlon=' + shop.longitude + '&z=18'" target="_blank" title="ouvre une nouvelle fenêtre" color="primary" elevation="1" class="mt-2" fab small @click.stop=""><v-icon>mdi-directions</v-icon></v-btn>
          <v-btn v-if="shop.phone" :href="'tel:'+shop.phone" color="primary" elevation="1" class="mt-2" fab small @click.stop=""><v-icon>call</v-icon></v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>

import { DEBUG } from "@/main";
import { useShopStore } from "@/stores/shop-store";
import { ShopService } from "@/services/shop-services";
import { checkOpeningState, navigate } from "@/utils";
import ShopLikeButton from "@/views/shop/components/ShopLikeButton.vue";
import { useNavStore } from "@/stores/navigation-store";

const shopService = new ShopService();

export default {
    name: "ShopCard",
    components: { ShopLikeButton },
    props: {
        shop: {
            type: Object,
            required: true
        },
        longAdress: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        loading: true,
        hours: null,
        shopCategory: null,
        isOpen: null,
    }),
    methods: {
        navigate() {
            this.navStore.pageLoading = true
            this.shopStore.setShop(this.shop)
            this.navStore.setPreviousRoute(this.$route)
            navigate(this.$router, { name: "shop", params: { id: this.shop.id } }).then(() => {
                this.navStore.pageLoading = false
            })
        },
        async checkOpening() {
          try {
              const horaires = await shopService.getShopHoraire(this.shop.id)
              if (horaires) {
                return checkOpeningState(horaires)
              }
          }
          catch (err) {
              if (DEBUG) {
                  console.error(err)
              }
          }
        },
    },
    async beforeMount() {
        this.shopCategory = this.shopStore.getCategoryById(this.shop.category)
        this.checkOpening().then(res => {
          this.isOpen = res
        })
    },
    setup() {
        const shopStore = useShopStore()
        const navStore = useNavStore()
        return {
            shopStore,
            navStore,
        }
    }
};
</script>