<template>
    <div class="map-page">
        <l-map :zoom="zoom" :center="center" :options="{zoomControl: false}">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker v-for="shop in getShops()" :key="shop.id" :lat-lng="[shop.latitude, shop.longitude]">
                <l-popup>
                    <div class="d-flex">
                        <img v-if="shop.photo.square" :src="shop.photo.square" height="30" width="30" alt="" class="me-2 rounded-circle">
                        <a :href="'/shop/'+shop.id" class="popup-title title" @click.prevent="navigate(shop.id)">{{ shop.name }}</a>
                    </div>
                    <div class="popup-desc" v-if="shop.description">{{ shop.description }}</div>
                </l-popup>
            </l-marker>
            <l-control-zoom position="bottomleft"></l-control-zoom>
        </l-map>
        <div class="map-categories">
            <div class="map-categories-container">
                <v-chip color="white" class="mx-2" active-class="primary white--text" v-for="cat in shopCategories" :key="cat.id"  :input-value="isActive(cat.id)" :close="isActive(cat.id)" @click="setCat(cat.id)" @click:close="resetCat">{{ cat.name }}</v-chip>
            </div>
        </div>
    </div>
</template>


<script>
import { useNavStore } from "@/stores/navigation-store";
import { useShopStore } from "@/stores/shop-store";
import { navigate } from "@/utils";
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('@/assets/map-marker-2.png'),
    iconUrl: require('@/assets/map-marker-2.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [32, 44],
    iconAnchor: [19, 44],
    shadowAnchor: [18, 41],
});


export default {
    name: 'MapPage',
    components: {  },
    data () {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 12,
            center: [16.259773, -61.389112],
            shopLis: [],
            shops: [],
            category: null,
            shopCategories: [],
        }
    },
    methods: {
        navigate(shopId) {
            this.navStore.pageLoading = true
            this.shopStore.setShop(this.shop)
            this.navStore.setPreviousRoute(this.$route)
            navigate(this.$router, { name: "shop", params: { id: shopId } }).then(() => {
                this.navStore.pageLoading = false
            })
        },
        getShops() {
            return this.shopList.filter((shop) => {
                if(shop.longitude != null && shop.latitude != null) {
                    if (this.category != null && this.category != shop.category) {
                        return false
                    }
                    return true
                }
                return false
            })
        },
        setCat(catId) {
            if (this.category != catId) {
                this.category = catId
                this.shops = this.getShops()
            }
        },
        resetCat() {
            this.category = null
            this.getShops()
        },
        isActive(catId) {
            return this.category == catId
        },
    },
    beforeMount() {
        this.navStore.setPageTitle('Carte')
        this.shopStore.$subscribe((callback, state) => {
            this.shopCategories = state.categories
            this.shopList = state.shopList
        })
        this.shopList = this.shopStore.shopList
        this.shopCategories = this.shopStore.categories
        this.navStore.pageLoading = false
    },
    setup() {
        const shopStore = useShopStore();
        const navStore = useNavStore()
        return {
            shopStore,
            navStore,
        }
    },
}
</script>