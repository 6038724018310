<template>
<v-dialog
    max-width="600px"
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
    <v-btn
        color="primary"
        plain
        small
        v-bind="attrs"
        v-on="on"
    >
        <v-icon>mdi-check-circle</v-icon>
        <span class="sr-only">Déclarer comme principale</span>
    </v-btn>
    </template>
    <v-card>
    <v-card-title>
        Êtes-vous sûr de vouloir définir le mail "{{ email.email }}" comme principal ?
    </v-card-title>
    <v-card-text>Ce mail sera utilisé par défaut pour toutes communications ou actions de correspondance avec les administrateurs</v-card-text>
    <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
            Non, revenir en arrière
        </v-btn>
        
        <v-btn
            color="success"
            class="ml-auto"
            text
            :loading="loading"
            @click="promote()"
        >
            Valider
        </v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { AuthenticationService } from "@/services/auth-services";
import { useAuthUser } from "@/stores/auth-store";

const authService = new AuthenticationService()

export default {
    name: "ProfileEmailPrimary",
    props: {
        email: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        loading: false,
        dialog: false
    }),
    methods: {
        async promote() {
            this.loading = true
            try {
                await authService.promoteEmail(this.email.email, this.authUser.getToken)
                this.$toast.success(`Le mail "${this.email.email}" a été défini comme principal`)
                this.authUser.emails.results = this.authUser.emails.results.map((mail) => {
                    mail.primary = mail.email === this.email.email ? true : false
                    return mail
                })
                this.dialog = false
            } catch (err) {
                this.$toast.error('L\'action a échoué. Si le problème persiste, contacter un administrateur')
            } finally {
                this.loading = false
            }
        }
    },
    setup() {
        const authUser = useAuthUser();
        return {
            authUser,
        };
    },
}
</script>