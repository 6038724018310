<template>
  <div class="profile-emails">
    <div class="title">
      Emails associés
      <small v-if="emails.count > 0">({{ emails.count }})</small>
    </div>
    <ProfileEmailAddForm v-if="emails.length > 25" />

    <ul class="categorie-list">
      <li class="categorie-list-item" v-for="email in emails.results" :key="email.email">
        <div class="categorie-list-item-text">{{ email.email }}</div>
        <v-badge dot :color="email.verified ? 'success' : 'danger'" inline></v-badge>
        <v-chip small color="amber" v-if="email.primary">Principale</v-chip>
        <div class="categorie-list-item-action">
          <ProfileEmailPrimary :email="email" v-if="!email.primary && email.verified" />
          <ProfileEmailDel :email="email" v-if="!email.primary" />
        </div>
      </li>
    </ul>
    <ProfileEmailAddForm />
  </div>
</template>

<style lang="scss"></style>

<script>
import { useAuthUser } from "@/stores/auth-store";
import { AuthenticationService } from "@/services/auth-services";
import ProfileEmailDel from "@/views/profile/components/ProfileEmailDel.vue";
import ProfileEmailAddForm from "@/views/profile/components/ProfileEmailAddForm.vue";
import ProfileEmailPrimary from "@/views/profile/components/ProfileEmailPrimary.vue";
import { DEBUG } from "@/main";

const authService = new AuthenticationService()

export default {
  name: "ProfileEmails",
  data: () => ({
    emails: []
  }),
  async beforeMount() {
    this.authUser.$subscribe((callback, state) => {
      this.emails = state.emails
    })

    try {
      this.authUser.emails = await authService.listEmails(this.authUser.token)
    } catch (err) {
      if (DEBUG) {
        console.error(err)
      }
      this.$toast.error('Impossible de récupérer la liste des emails')
    }
  },
  setup() {
    const authUser = useAuthUser()

    return {
      authUser,
    }
  },
  components: { ProfileEmailDel, ProfileEmailAddForm, ProfileEmailPrimary }
};
</script>



