<template>
  <div class="py-4">
    <Order v-for="order, i in orders" :order="order" :key="'order-' + i + '-' + order.reference" />
  </div>
</template>

<style lang="scss"></style>

<script>
import { formatPrice } from "@/utils";
import Order from "@/views/orders/components/Order.vue";

export default {
  name: "OrderList",
  props: {
    orders: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      getOptions: {},
    };
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
  },
  components: { Order }
};
</script>



