import { getCookie, setCookie } from "@/utils";
import { defineStore } from "pinia";

export const useCartStore = defineStore('cartStore', {
    state: () => {
        return {
            carts: {},
            count: 0
        }
    },
    actions: {
        initCart() {
            const cartsCookie = JSON.parse(getCookie('clickncollect-carts'))
            if (cartsCookie) {
                this.carts = cartsCookie
                this.count = Object.entries(cartsCookie).length
            }
        },
        /**
         * Sauvegarder le panier dans le store et le cookie
         * @param {Integer} shopId 
         * @param {Object} cart 
         */
        setCart(shopId, cart) {
            this.carts[shopId] = cart
            this.count = Object.entries(this.carts).length
            setCookie('clickncollect-carts', JSON.stringify(this.carts), 14)
        },
        /**
         * Vider le panier d'un shop
         * @param {Integer} shopId 
         */
        clearCart(shopId) {
            delete this.carts[shopId]
            this.count = Object.entries(this.carts).length
            setCookie('clickncollect-carts', JSON.stringify(this.carts), 14)
            return this.carts
        },
        /**
         * Mettre à jour ligne du panier
         * @param { Object.<shop: Integer, product:Integer,quantity: Integer, unit_price_ttc:Integer> } item 
         * @returns 
         */
        updateCartItem(item) {
            const shopId = item.shop
            const cart = this.carts[shopId]
            if (cart) {
                const itemIndex = cart.items.findIndex(line => line.product == item.product)
                if (itemIndex > -1) {
                    cart.items[itemIndex].quantity = item.quantity
                    cart.items[itemIndex].unit_price_ttc = item.unit_price_ttc
                    this.setCart(shopId, cart)
                    return true
                }
            }
            return false
        },
        /**
         * Ajouter article au panier
         * @param { Object.<shop: Integer, product:Integer,quantity: Integer, unit_price_ttc:Integer> } item 
         * @returns 
         */
        addToCart(item) {
            const shopId = item.shop
            const cart = this.carts[shopId] || {items: []}
            if (cart.items.length == 0) {
                cart.items.push(item)
                this.setCart(shopId, cart)
                return true
            } else {
                const itemIndex = cart.items.findIndex(line => line.product == item.product)
                if (itemIndex == -1) {
                    cart.items.push(item)
                    this.setCart(shopId, cart)
                    return true
                } else {
                    cart.items[itemIndex].quantity += item.quantity
                    this.setCart(shopId, cart)
                    return true
                }
            }
        },
        /**
         * Supprimer article au panier
         * @param { Object.<shop: Integer, product:Integer,quantity: Integer, unit_price_ttc:Integer> } item 
         * @returns 
         */
        removeCartItem(item) {
            const shopId = item.shop
            const cart = this.carts[shopId]
            if (cart) {
                const itemIndex = cart.items.findIndex(line => line.product == item.product)
                if (itemIndex > -1) {
                    cart.items.splice(itemIndex,1)
                    this.setCart(shopId, cart)
                    return true
                }
            }
            return false
        },
    },
})