<template>
  <div class="products">
    <template>
      <div v-if="loading" style="height:300px">
          <v-row class="fill-height text-center" align-content="center" justify="center">
              <v-col cols=12>
                  <v-progress-circular
                      color="primary"
                      indeterminate
                      :size="70"
                  ></v-progress-circular>
              </v-col>
          </v-row>
      </div>
      <div v-if="!loading">
        <div class="product-card no-btn" v-if="!products.length">
          <div class="product-card-content text-center">
            <div class="product-card-text">
              <div class="mb-2">
                <v-icon color="primary" x-large>production_quantity_limits</v-icon>
              </div>
              <strong>Aucun produits.</strong>
            </div>
          </div>
        </div>
        <ProductList :products="products" v-if="!loading && products.length" />
      </div>
    </template>
  </div>
</template>

<style lang="scss">
</style>

<script>
import { useShopStore } from "@/stores/shop-store";
import ProductList from "@/views/products/components/ProductList.vue";
import { ShopService } from "@/services/shop-services";
import { useNavStore } from "@/stores/navigation-store";

const shopService = new ShopService();

export default {
  name: "Products",
  data() {
    return {
      loading: true,
      products: [],
      productCategories: [],
    };
  },
  methods: {
    async getProducts() {
      const products = await shopService.getShopProducts(this.shopId);
      this.loading = false
      if (products) {
        return products;
      }
      return [];
    },
    async getCategories() {
      const productCategories = await shopService.getProductCategories(this.shopId);
      if (productCategories) {
        return productCategories;
      }
      return [];
    },
    refreshProducts() {
      this.getProducts().then((products) => {
        this.products = products
        this.loading = false
      })
    },
    infiniteScroll(entries) {
      if (entries[0].isIntersecting && this.nextPage) {
        this.getProducts().then(products => products.forEach((p) => this.products.push(p)))
      }
    },
  },
  async beforeMount() {
    this.shopStore.$subscribe(async (callback, state) => {
      this.productCategories = state.productCategories
    });
    this.productCategories = await this.getCategories()
    this.shopStore.productCategories = this.productCategories
    this.refreshProducts();
  },
  setup() {
    const shopStore = useShopStore();
    const navStore = useNavStore()
    return {
      shopStore,
      navStore,
    };
  },
  components: { ProductList },
  computed: {
    shopId () {
      return this.$route.params.id
    }
  },
};
</script>



