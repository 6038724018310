<template>
  <div class="product-list">
    <v-row>
      <v-col v-for="product in products" :key="product.id" cols="12" sm="6" md="4" lg="3">
        <div class="product-card" :class="{ unpublished: !product.published }" @click.prevent="navigate(product.id)">
          <v-img v-if="product.photo.square" :src="product.photo.square" class="product-card-img">
              <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
              </template>
          </v-img>
          <div class="product-card-content">
            <div class="product-card-content-title">
              {{ product.name }}
            </div>
            <div class="product-card-content-info">
              <div class="product-card-content-info-value" v-if="product.category">
                <v-icon color="amber" small>bookmark</v-icon>
                <span>{{ product.category.name }}</span>
              </div>
            </div>
            <div class="product-card-taxe">
              <div v-if="product.stock > 0 && product.stock <= 10" class="stock">
                Plus que {{ product.stock }}
              </div>
              <span v-if="product.stock <= 0" class="stock empty text-danger">
              <v-icon color="danger" small>inventory</v-icon>
                Indisponible
              </span>
            </div>
            <div class="product-card-price">
              <span class="exergue">{{ formatPrice(product.price_ttc) }}</span>€
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss"></style>

<script>
import { useNavStore } from "@/stores/navigation-store";
import { useShopStore } from "@/stores/shop-store";
import { formatPrice, navigate } from "@/utils";

export default {
  name: "ProductList",
  props: {
    products: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      getProductOptions: {},
      productCategory: null
    };
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
    getCategoryName(categoryId) {
      const categorie = this.shopStore.getProductCategoryById(categoryId)
      if (categorie.length > 0) {
        return categorie[0].name
      }
      return ''
    },
    navigate(productId) {
      this.navStore.pageLoading = true
      let routerOptions = {name:'product', params: {id: productId}}
      this.navStore.setPreviousRoute(this.$route)
      navigate(this.$router, routerOptions)
    }
  },
  setup() {
    const shopStore = useShopStore();
    const navStore = useNavStore()
    return {
      shopStore,
      navStore,
    };
  },
};
</script>



