<template>
    <div class="cart-page">
        <Carts />
    </div>
</template>

<script>
import { useNavStore } from "@/stores/navigation-store";
import Carts from "@/views/cart/components/Carts.vue";

export default {
    name: "CartPage",
    components: { Carts },
    beforeMount() {
        this.navStore.setPageTitle('Panier')
    },
    setup() {
        const navStore = useNavStore()
        return { navStore }
    }
}

</script>