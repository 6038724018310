import { DEBUG } from "@/main";
import { VueHttp } from "@/vue-http";

export class ShopService {

  async getShopsByCategory(id) {
    try {
      const res = await VueHttp.get(`store?category=${id}`);
      return res.data.results;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getCategories() {
    try {
      const res = await VueHttp.get("category/");
      return res.data.results
    } catch (error) {
      throw error.response.data;
    }
  }

  async getShops() {
    try {
      const res = await VueHttp.get(`store/`);
      return res.data.results;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getShopsMap() {
    try {
      const res = await VueHttp.get(`store/`);
      const data = res.data.results;
      const map = data.map((el) => ({
        longitude: parseFloat(el.longitude),
        latitude: parseFloat(el.latitude),
        name: el.name,
        id: el.id,
        photo: el.photo.small,
        description: el.description,
        category: el.category
      }));
      return map;
    } catch (error) {
      throw error.response.data;
    }
  }

  async getProductCategories(shopId) {
    try {
      const res = await VueHttp.get(`store/${shopId}/categories/`);
      return res.data.results;
    } catch (error) {
      if (DEBUG) {
        console.error(error)
      }
      this.$toast.error("Une erreur s'est produite lors du chargement des catégories de produits");
    }
  }

  async getShopById(id) {
    try {
      const res = await VueHttp.get(`store/${id}/`);
      return res.data;
    } catch (error) {
      if (DEBUG) {
        console.error(error)
      }
      this.$toast.error("Une erreur s'est produite lors du chargement du commerçant");
    }
  }

  async getShopProducts(id) {
    try {
      const res = await VueHttp.get(`store/${id}/product/`);
      const prodCat = await this.getProductCategories(id);

      const data = res.data.results.map((el) => {
        const elId = el.category;
        const filt = prodCat.filter((val) => val.id == elId);

        if (filt.length > 0) {
          el.category = { id: elId, name: filt[0].name };
        }
        return el;
      });

      return data;
    } catch (error) {
      if (DEBUG) {
        console.error(error)
      }
      this.$toast.error("Une erreur s'est produite lors du chargement de la liste des produits");
    }
  }

  async getShopHoraire(id) {
    try {
      const res = await VueHttp.get(`store/${id}/hour/`);
      return res.data.results;
    } catch (error) {
      if (DEBUG) {
        console.error(error)
      }
    }
  }

  async searchShops(val) {
    try {
      const res = await VueHttp.get(`store?search=${val}`);
      return res.data.results;
    } catch (error) {
      throw error.response.data;
    }
  }
}