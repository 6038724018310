<template>
    <v-row>
        <v-col cols="12" class="pb-0"><span class="section-title">Les Commerçants</span></v-col>
        <v-container v-if="loading" style="height:300px">
            <v-row class="fill-height text-center" align-content="center" justify="center">
                <v-col cols=12>
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        :size="70"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-container>
        <v-col cols="12" v-if="!loading">
            <v-slide-group max="2" v-if="shops.length > 0">
                <v-slide-item v-for="shop in shops" :key="'shop-'+shop.id">
                    <div class="shop-carousel-item mx-2 mb-6"><ShopCard :shop="shop" :longAdress="false"/></div>
                </v-slide-item>
            </v-slide-group>
            <div class="product-card no-btn" v-else>
                <div class="product-card-content text-center">
                    <div class="product-card-text">
                    <div class="mb-2">
                        <v-icon color="primary" x-large>mdi-store-off</v-icon>
                    </div>
                    <strong>Aucun commerçant</strong>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { useShopStore } from "@/stores/shop-store";
import ShopCard from "@/views/shop/components/ShopCard.vue";
import { shuffleArray } from "@/utils";

export default {
    name: "Shops",
    data: () => ({
        shops: [],
        loading: true,
    }),
    beforeMount() {
        this.shopStore.$subscribe(async (callback, state) => {
            this.shops = await state.shopList;
        })
        this.shops = shuffleArray(this.shopStore.shopList);
        this.loading = false;
    },
    setup() {
        const shopStore = useShopStore();
        return {
            shopStore,
        };
    },
    components: { ShopCard }
}
</script>