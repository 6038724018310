<template>
  <header id="header" :class="{ 'bg-none' : isTransparent(), 'sticky' : sticky, 'py-3' : collapseMenu }" v-scroll:#main-content="onScroll">
    <div class="container-fluid">
      <nav role="navigation">
        <v-btn plain small fab :disabled="$route.name == 'home'" @click.prevent="goBack()">
          <v-icon v-if="$route.name != 'home'">mdi-arrow-left</v-icon>
          <span class="sr-only">Retour</span>
        </v-btn>
        <div class="header-page-name d-block d-md-none" v-if="$route.name != 'home' && pageTitle && !authUser.pageLoading">
          {{ pageTitle }}
        </div>

        <div class="header-brand" v-if="$route.name == 'home'">
          <div class="header-brand-logo d-none d-md-block">
            <img src="@/assets/client.jpg" alt />
          </div>
          <div class="header-brand-content" :class="{ 'text-center':collapseMenu }">
            <div class="header-brand-title">
              <span class="before">Click N'C</span>
              <span class="center d-none d-md-block">o</span>
              <span class="header-brand-title-logo d-md-none d-block"><img src="@/assets/client.jpg" alt /></span>
              <span class="after">llect</span>
              </div>
            <div class="header-brand-text">Ville de Sainte-Anne</div>
          </div>
        </div>

        <div class="header-collapse" v-if="!collapseMenu || toggleMenu">
          <ul class="header-menu">
            <li>
              <a
                href="/"
                :class="{ active: isActive('home') }"
                @click.prevent="navigate('home')"
              >
                <v-icon class="header-nav-icon">home</v-icon>
                <span class="header-nav-text">Accueil</span>
              </a>
            </li>
            <li>
              <a
                href="/search"
                :class="{ active: isActive('search') }"
                @click.prevent="navigate('search')"
              >
                <v-icon class="header-nav-icon">mdi-magnify</v-icon>
                <span class="header-nav-text">Rechercher</span>
              </a>
            </li>
            <li>
              <a
                href="/map"
                :class="{ active: isActive('map') }"
                @click.prevent="navigate('map')"
              >
                <v-icon class="header-nav-icon">mdi-map-marker-multiple</v-icon>
                <span class="header-nav-text">Carte</span>
              </a>
            </li>
            <li>
              <a
                href="/favorites"
                :class="{ active: isActive('favorites') }"
                @click.prevent="navigate('favorites')"
              >
                <v-icon class="header-nav-icon">mdi-heart</v-icon>
                <span class="header-nav-text">Favoris</span>
              </a>
            </li>
            <li>
              <a
                href="/user/orders"
                :class="{ active: isActive('orders') }"
                @click.prevent="navigate('orders')"
              >
                <v-icon class="header-nav-icon">shopping_basket</v-icon>
                <span class="header-nav-text">Commandes</span>
              </a>
            </li>
            <li>
              <a
                href="/cart"
                :class="{ active: isActive('cart') }"
                @click.prevent="navigate('cart')"
              >
                <v-icon class="header-nav-icon">mdi-cart</v-icon>
                <span class="header-nav-text">Panier</span>
              </a>
            </li>
          </ul>

          <!-- <a href="#" role="button" @click.prevent="logOut" class="header-nav-action border-left">
            <v-icon class="header-nav-icon" color="primary">mdi-logout-variant</v-icon>
            <span class="ms-1" :class="{ 'sr-only': !collapseMenu }">Se déconnecter</span>
          </a> -->
        </div>

        <v-btn plain small fab v-if="collapseMenu" @click.prevent="navigate('cart')" class="header-nav-btn" :class="{ active : isActive('cart') }">
            <v-badge icon color="red" :content="cartCount" :value="cartCount" overlap>
              <v-icon class="header-nav-icon">mdi-cart</v-icon>
            </v-badge>
            <span class="sr-only">Panier</span>
        </v-btn>
        
        <a href="/user/profile" v-if="!collapseMenu" @click.prevent="navigate('profile')" class="header-nav-account">
          <span class="header-nav-account-icon">
            <v-icon class="header-nav-icon">mdi-account</v-icon>
          </span>
          <span class="header-nav-account-text d-none d-md-block" v-if="user">
            Bienvenue
            <strong>{{ user.first_name }} {{ user.last_name }}</strong>
          </span>
        </a>
      </nav>
    </div>
  </header>
</template>

<style lang="scss"></style>


<script>
import { AuthenticationService } from "@/services/auth-services";
import { useAuthUser } from "@/stores/auth-store";
import { useCartStore } from "@/stores/cart-store";
import { useNavStore } from "@/stores/navigation-store";
import { navigate } from "@/utils";

const authService = new AuthenticationService();

export default {
    name: "Header",
    data: () => ({
        selectedMenu: "home",
        user: null,
        toggleMenu: false,
        pageTitle: "",
        sticky: false,
        cartCount: null,
    }),
    computed: {
        collapseMenu() {
            switch (this.$vuetify.breakpoint.name) {
                case "md": return true;
                case "sm": return true;
                case "xs": return true;
            }
            return false;
        }
    },
    methods: {
        onScroll(e) {
          let currentOffset = e.target.scrollTop
          if (currentOffset > 0) {
            this.sticky = true
          } else {
            this.sticky = false
          }
        },
        async logOut() {
            this.$toast.info("Déconnection en cours");
            try {
                await authService.logOut(this.authUser.getToken);
                this.authUser.clearAuth();
                this.$router.push({
                    name: "login",
                });
            }
            catch {
                this.$toast.error("Une erreur s'est produite lors de votre déconnection. Veuillez contacter un administrateur.");
            }
        },
        isTransparent() {
          let routes = ['shop','product']
          return routes.includes(this.$route.name) ? true : false;
        },
        isActive(routerName) {
            return routerName == this.selectedMenu ? true : false;
        },
        isOnglet() {
          let onglets = ['home','search','map','favorites','profile']
          return onglets.includes(this.$route.name) ? true : false;
        },
        navigate(routerName) {
            if (this.selectedMenu != routerName) {
                this.navStore.pageLoading = true
                navigate(this.$router, { name: routerName })
            }
        },
        goBack() {
          this.$router.go(-1)

          // let previousRoute = this.navStore.previousRoute
          // this.navStore.pageLoading = true
          // if (previousRoute.name != '' && previousRoute.name != null) {
          //   this.navStore.setSelectedMenu(previousRoute.name)
          // } else {
          //   this.navStore.setSelectedMenu('home')
          // }
          // navigate(this.$router, previousRoute).then(() => {
          //     this.navStore.pageLoading = false
          // });
        }
    },
    beforeMount() {
        this.selectedMenu = this.navStore.getSelectedMenu;
        this.pageTitle = this.navStore.pageTitle;
        this.navStore.$subscribe((callback, state) => {
            this.selectedMenu = state.selectedMenu;
            this.user = state.user;
            this.pageTitle = state.pageTitle;
        })
        this.cartStore.$subscribe((callback, state) => {
            this.cartCount = state.count
        })
        this.cartCount = this.cartStore.count

        if (this.navStore.previousRoute == undefined) {
          this.navStore.setPreviousRoute(this.$route)
        }
    },
    setup() {
        const authUser = useAuthUser()
        const cartStore = useCartStore()
        const navStore = useNavStore()
        return {
            authUser,
            cartStore,
            navStore,
        };
    },
};
</script>