import { defineStore } from 'pinia'

export const useNavStore = defineStore('navStore', {

  state: () => {
    return {
      pageLoading: false,
      selectedMenu: undefined,
      pageTitle: '',
      previousRoute: undefined,
      history: [],
    }
  },
  actions: {
    setSelectedMenu(menu) {
      this.selectedMenu = menu;
      sessionStorage.setItem('selectedMenu', menu);
    },
    setPageTitle(pageTitle) {
      this.pageTitle = pageTitle;
    },
    setPreviousRoute(route) {
      this.previousRoute = route;
    },
    addHistory(onglet, route) {
      const timestamp = new Date().getTime()
      this.history.push({'onglet': onglet,'route': route, 'timestamp': timestamp})
    }
  },
  getters: {
    getSelectedMenu: (state) => {
      if (state.selectedMenu === undefined) {
        state.selectedMenu = sessionStorage.getItem('selectedMenu');
        if (!state.selectedMenu) {
          state.selectedMenu = 'home'
        }
      }
      return state.selectedMenu;
    },
  }
})