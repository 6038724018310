<template>
    <div class="cart-shop">
        <div v-if="loading" style="height:100px">
            <v-row class="fill-height text-center" align-content="center" justify="center">
                <v-col cols=12>
                    <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
            </v-row>
        </div>
        <div class="cart-shop-container" v-if="!loading">
            <div class="cart-shop-top">
                <div class="cart-shop-title" @click.prevent="navigate({name: 'shop', params: {id: shop.id}})">{{ shop.name }} <span class="cart-shop-count">({{ items.length }} {{ items.length > 1 ? 'articles' : 'article' }})</span></div>
                <v-btn elevation="0" icon color="danger" @click.prevent="clearCart"><v-icon>delete</v-icon></v-btn>
            </div>
            <CartLine v-for="item in items" :item="item" :key="item + '-' + item.product" @update="refreshTotal" />
            <strong v-if="items.length == 0">Ce panier est vide</strong>
            <div class="cart-shop-total">Total : <span class="exergue">{{ formatPrice(total) }}</span>€</div>
        </div>
        
        <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="cart-shop-order-button mt-6" block color="primary" v-bind="attrs" v-on="on">Commander</v-btn>
            </template>
            <v-card>
                <v-card-title>{{ authUser.token == "" ? "Vous n'êtes pas connecté" : "Envoi de la commande" }}</v-card-title>
                <v-card-text>
                    {{ authUser.token == "" ? "Veuillez vous connecter pour valider votre commande" : `Êtes-vous sûr de vouloir envoyer votre commande à ${shop.name} ?`  }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="danger" text @click="dialog = false"  v-if="authUser.token != ''">Annuler</v-btn>
                    <v-btn color="success" text @click="sendOrder"  v-if="authUser.token != ''">Valider</v-btn>
                    <v-btn color="primary" text @click="dialog=false;navigate({name:'login'})"  v-if="authUser.token == ''">S'identifier</v-btn>
                    <v-btn text @click="dialog=false;navigate({name:'login'})"  v-if="authUser.token == ''">Créer un compte</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { useShopStore } from "@/stores/shop-store";
import { useCartStore } from "@/stores/cart-store";
import CartLine from "@/views/cart/components/CartLine.vue";
import { OrderService } from "@/services/order-services";
import { useAuthUser } from "@/stores/auth-store";
import { formatPrice, navigate } from "@/utils";
import { useNavStore } from "@/stores/navigation-store";

const orderService = new OrderService()

export default {
    name: "Cart",
    props: {
        cart: {
            type: Array,
            required: true,
        },
    },
    components: { CartLine },
    data() {
        return {
            shop: {},
            items: [],
            orderLines: [],
            total: 0,
            loading: true,
            dialog: false,
        }
    },
    methods: {
        clearCart() {
            this.$emit('clear', this.cart[0])
        },
        async sendOrder() {
            this.dialog = false
            if (this.authUser.getToken != "") {
                try {
                    const order = await orderService.createOrder(this.cart[0], this.authUser.pk, this.orderLines, this.authUser.getToken)
                    if (order) {
                        this.clearCart()
                        this.$toast.success("Votre commande a bien été envoyée.")
                    }
                } catch (error) {
                    this.$toast.error("Une erreur est survenue lors de l'envoi de la commande")
                }
            }
        },
        navigate(routerOptions) {
            this.navStore.pageLoading = true
            this.shopStore.setShop(this.shop)
            this.navStore.setPreviousRoute(this.$route)
            navigate(this.$router, routerOptions).then(() => {
                this.navStore.pageLoading = false
            })
        },
        formatPrice(price) {
            return formatPrice(price)
        },
        refreshTotal() {
            let total = 0
            this.items.forEach(item => {
                total += item.quantity * item.unit_price_ttc
            })
            this.total = total
        },
    },
    beforeMount() {
        this.shop = this.shopStore.getShopById(this.cart[0])
        this.items = this.cart[1].items
        this.cart[1].items.forEach(item => {
            this.orderLines.push({'product': item.product, 'quantity': item.quantity})
            this.total += item.quantity * item.unit_price_ttc
        })
        if (this.shop && this.items) {
            this.loading = false
        }
    },
    setup() {
        const shopStore = useShopStore()
        const cartStore = useCartStore()
        const authUser = useAuthUser()
        const navStore = useNavStore()
        return {
            shopStore,
            cartStore,
            authUser,
            navStore,
        }
    }
}

</script>