<template>
<div class="order-card-line">
    <div class="order-card-line-info">
        <h3>{{ line.product_name }}</h3>
        <div class="order-card-line-price text-start">
            <span class="order-card-line-qty">
                <abbr title="Prix unitaire hors taxe">PU HT</abbr>
            </span>
            {{ formatPrice(line.unit_price_ht) }}€
        </div>
    </div>
    <div class="order-card-line-price">
        <div class="order-card-line-qty">
            <abbr title="Quantité">Qté</abbr>
        </div>
        {{ line.quantity }}
    </div>
    <!-- <div class="order-card-line-price">
        <div class="order-card-line-qty">
            <abbr title="Prix unitaire hors taxe">PU HT</abbr>
        </div>
        {{ formatPrice(line.unit_price_ht) }}€
    </div> -->
    <!-- <div class="order-card-line-price">
        <div class="order-card-line-qty">TVA</div>
        {{ line.taxe }}%
    </div> -->
    <div class="order-card-line-price mx-2">
        <div class="order-card-line-qty">Total HT</div>
        {{ formatPrice(line.total_ht) }}€
    </div>
    <div class="order-card-line-price">
        <div class="order-card-line-qty">Total TTC</div>
        {{ formatPrice(line.total_ttc) }}€
    </div>
</div>
</template>

<style lang="scss"></style>

<script>
import { DEBUG } from "@/main";
import { ProductService } from "@/services/product-services";
import { useAuthUser } from "@/stores/auth-store";
import { useOrderStore } from "@/stores/order-store";
import { useShopStore } from "@/stores/shop-store";
import { formatPrice } from "@/utils";

const productService = new ProductService()

export default {
    name: "OrderLine",
    props: {
        line: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        dialog: false,
        photo: null
    }),
    methods: {
        formatPrice(price) {
            return formatPrice(price);
        },
        couper(text, limit) {
            return text.length > limit ? text.substring(0,limit)+'...' : text
        },
    },
    async beforeMount() {
        if (this.shopStore.category?.photo?.square) this.photo = {url: this.shopStore.category.photo.square, type: 'default'}
        if (this.shopStore.shop?.photo?.square) this.photo = {url: this.shopStore.shop.photo.square, type: 'default'}

        let product = this.orderStore.products.filter(val => val.id === this.line.product)
        if(product.length > 0) {
            product = product[0]
        } else {
            product = null
            if (this.line.product) {
                try {
                    product = await productService.getProduct(this.line.product)
                    this.orderStore.products.push(product)
                } catch (err) {
                    if(DEBUG) {
                        console.error(err)
                    }
                }
            }
        }
        if (product?.photo?.square) this.photo = {url: product.photo.square, type: 'product'}
    },
    setup() {
      const authUser = useAuthUser()
      const orderStore = useOrderStore()
      const shopStore = useShopStore()
      return {
        authUser,
        orderStore,
        shopStore
      }
    }
};
</script>



