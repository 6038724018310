<template>
<div class="main-view-page">
    <PageLoader />
    <Header />
    <main id="main-content">
      <div class="container" v-if="shopStoreloaded">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </main>
    <Footer />
</div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLoader from "@/components/PageLoader.vue";
import { useCartStore } from "@/stores/cart-store";
import { useFavStore } from "@/stores/favorites-store";
import { useShopStore } from "@/stores/shop-store";
import { ShopService } from "@/services/shop-services";
import { DEBUG } from "@/main";
import { useNavStore } from "@/stores/navigation-store";

const shopService = new ShopService()

export default {
    name: 'PublicView',
    components: {
        Header,
        Footer,
        PageLoader
    },
    data: () => {
        return {
            shopStoreloaded: false
        }
    },
    methods: {
        async initShopStores() {
            try {
                const shops = await shopService.getShops()
                this.shopStore.setShopList(shops)
                return true
            } catch (error) {
                if (DEBUG) {
                    console.error(error)
                }
                return false
            }
        },
        async initShopCategories() {
            try {
                this.shopStore.categories = await shopService.getCategories()
                return true
            } catch (error) {
                if (DEBUG) {
                    console.error(error)
                }
                return false
            }
        },
        // todo : refreshStoresInterval() {}
    },
    setup() {
        const cartStore = useCartStore()
        const favStore = useFavStore()
        const shopStore = useShopStore()
        const navStore = useNavStore()
        return { cartStore, favStore, shopStore, navStore }
    },
    beforeMount() {
        this.cartStore.initCart()
        this.favStore.initFavList()
        this.navStore.pageLoading = true
        this.initShopCategories().then(() => {
            this.initShopStores().then((res) => {
                this.shopStoreloaded = res
                this.navStore.pageLoading = false
            })
        }, (e) => {
            if (DEBUG) {
                console.error(e)
            }
        })
    }
}
</script>