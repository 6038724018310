<template>
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <v-card class="mb-3" v-bind="attrs" v-on="on">
          <v-card-title class="pb-0">Ref. #{{ order.reference }}
            <v-chip x-small class="ms-2" :color="getStateColor(order.state)" :dark="['danger','dark'].includes(getStateColor(order.state))">{{ getState(order.state).value }}</v-chip>
          </v-card-title>
          <v-card-text>
            <div class="">Vendeur : <strong>{{ order.store_name }}</strong></div>
            <div class="d-block justify-space-between">
              <div class="order-card-infos">
                <div class="-info">
                  <span><v-icon>mdi-cart-check</v-icon></span> 
                  <strong>{{ formatDate(order.created) }}</strong>
                </div>
                <div class="order-info order-articles">
                  <span><v-icon>mdi-package-variant-closed</v-icon></span>
                  <span>{{ getProductCount(order.lines) }} {{ getProductCount(order.lines) > 1 ? 'articles' : 'article' }}</span>
                </div>
                <div class="-info text-success" v-if="order.state === 'available'">
                  <span><v-icon color="success">mdi-package-variant-closed-check</v-icon></span>
                  <span class="sr-only">Prête le :</span>
                  <strong>{{ formatDate(order.updated) }}</strong>
                </div>
                <div class="-info text-dark" v-if="order.state === 'collected'">
                  <span><v-icon color="dark">mdi-receipt-text-check</v-icon></span>
                  <span class="sr-only">Récupérée le :</span>
                  <strong>{{ formatDate(order.collected) }}</strong>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <div>Total HT: <span class="exergue">{{ formatPrice(order.total_ht) }}</span>€</div>
                <div>Total TTC: <span class="exergue">{{ formatPrice(order.total_ttc) }}</span>€</div>
              </div>
            </div>
            <div class="d-none" v-for="(value, key) in order" :key="key">{{ key }} : {{ value }}</div>
          </v-card-text>
        </v-card>
      </template>
      <v-card>
          <v-card-title class="pb-0">Ref. #{{ order.reference }}
            <v-chip x-small class="ms-2" :color="getStateColor(order.state)" :dark="['danger','dark'].includes(getStateColor(order.state))">{{ getState(order.state).value }}</v-chip>
          </v-card-title>
          <v-card-text>
            <div class="">Vendeur : <strong>{{ order.store_name }}</strong></div>
            <div class="order-card-infos">
              <div class="order-card-info">
                <span><v-icon>mdi-cart-check</v-icon></span> 
                <strong>{{ formatDate(order.created) }}</strong>
              </div>
              <div class="order-card-info">
                <span><v-icon>mdi-package-variant-closed</v-icon></span>
                <span>{{ getProductCount(order.lines) }} {{ getProductCount(order.lines) > 1 ? 'articles' : 'article' }}</span>
              </div>
              <div class="order-card-info text-success" v-if="order.state === 'available'">
                <span><v-icon color="success">mdi-package-variant-closed-check</v-icon></span>
                <span class="sr-only">Prête le :</span>
                <strong>{{ formatDate(order.updated) }}</strong>
              </div>
              <div class="order-card-info text-dark" v-if="order.state === 'collected'">
                <span><v-icon color="dark">mdi-receipt-text-check</v-icon></span>
                <span class="sr-only">Récupérée le :</span>
                <strong>{{ formatDate(order.collected) }}</strong>
              </div>
            </div>
            <div class="d-flex justify-space-between">
              <div>Total HT: <span class="exergue">{{ formatPrice(order.total_ht) }}</span>€</div>
              <div>Total TTC: <span class="exergue">{{ formatPrice(order.total_ttc) }}</span>€</div>
            </div>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-title class="pb-0">Détails de la commande :</v-card-title>
          <v-card-text class="order-card-lines">
            <OrderLine v-for="line in order.lines" :line="line" :key="line.order + '-' + line.product + '-' + line.unit_price" />
          </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style lang="scss"></style>

<script>
import { useShopStore } from "@/stores/shop-store";
import { allowedState, formatPrice, orderStates } from "@/utils";
import OrderLine from "@/views/orders/components/OrderLine.vue";
import { DateTime } from "luxon";
import { OrderService } from '@/services/order-services';
import { useAuthUser } from '@/stores/auth-store';
import { ShopService } from "@/services/shop-services";

const orderService = new OrderService()
const shopService = new ShopService()

export default {
  name: "Order",
  props: {
    order: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    dialog: false,
    shop: {},
    loading: true,
  }),
  computed: {
    allowedAction() {
      return allowedState(this.order.state)
    }
  },
  methods: {
    formatPrice(price) {
      return formatPrice(price);
    },
    getState(state) {
      return orderStates.filter((s) => s.id === state)[0];
    },
    getCustomerName(customer) {
      if (customer) {
        if (customer.first_name || customer.last_name) {
          return `${customer.first_name} ${customer.last_name}`;
        }
      }
      return "- Anonyme -";
    },
    formatDate(date) {
      return DateTime.fromISO(date).toLocaleString();
    },
    async print() {
      const res = await orderService.print(this.order.reference, this.authUser.getToken)
      const blob = new Blob([res], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `order_${this.order.reference}.pdf`
      link.click()
      link.remove()
    },
    async getShops() {
      const shops = await shopService.getShops()
      if (shops) {
          return shops;
      }
      return [];
    },
    getProductCount(lines) {
      let count = 0
      lines.forEach(line => {
        count += line.quantity
      })
      return count
    },
    getStateColor(state) {
      const colors = {
        'sent': 'warning',
        'accepted': 'amber',
        'refused': 'danger',
        'in_progress': 'primary',
        'canceled': '',
        'available': 'success',
        'collected': 'dark',
      }
      return colors[state]
    }
  },
  setup() {
    const shopStore = useShopStore()
    const authUser = useAuthUser()

    return {
      shopStore,
      authUser
    }
  },
  components: { OrderLine },
  beforeMount() {
    this.loading = false
    // this.getShops().then((shops) => {
    //   this.shopStore.setShopList = shops
    //   this.loading = false
    // })
  }
};
</script>



