<template>
    <div class="custom-header-container" v-if="product.id">
        <div class="custom-header-top">
            <v-img v-if="product.photo.large" :src="product.photo.large" class="custom-header-bg">
                <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
                </template>
            </v-img>
            <v-img v-if="!product.photo.large && productCategory.photo.large" :src="productCategory.photo.large" class="custom-header-bg">
                <template v-slot:placeholder>
                <div class="d-flex align-center justify-center fill-height placeholder-glow"></div>
                </template>
            </v-img>
            <v-chip v-if="productCategory" small color="white" class="custom-header-category">
                <v-icon small color="amber" class="me-1" left>mdi-bookmark</v-icon>{{ productCategory.name }}
            </v-chip>
        </div>
        <div class="custom-header-content container">
            <div class="custom-header-page-name">{{ product.name }}
            <v-btn v-if="product.latitude && product.longitude" :href="'https://www.openstreetmap.org/?mlat=' + product.latitude + '&mlon=' + product.longitude + '&z=18'" target="_blank" title="ouvre une nouvelle fenêtre" color="primary" elevation="1" class="ms-2 float-right" fab small @click.stop=""><v-icon>mdi-directions</v-icon></v-btn></div>
            <div class="custom-header-infos">
                <div class="desc mb-2">
                    <span class="text">{{ product.description }}</span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'ProductHeader',
    props: {
        product: {
            type: Object,
            required: true,
        },
        productCategory: {
            type: Object,
        }
    },
    data() {
        return {
            loading: true,
        }
    },
}
</script>