<template>
    <div class="carts">
        <Cart v-for="cart in carts" :cart="cart" :key="'cart-'+cart[0]" @clear="clearCart" />
        <div class="product-card no-btn" v-if="carts.length == 0">
            <div class="product-card-content text-center">
                <div class="product-card-text">
                <div class="mb-2">
                    <v-icon color="primary" x-large>production_quantity_limits</v-icon>
                </div>
                <strong>Votre panier est vide</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCartStore } from "@/stores/cart-store";
import { useNavStore } from "@/stores/navigation-store";
import Cart from "@/views/cart/components/Cart.vue";


export default {
    name: "Carts",
    components: { Cart },
    data() {
        return {
            loading: true,
            carts: {}
        }
    },
    methods: {
        clearCart(shopId) {
            this.carts = Object.entries(this.cartStore.clearCart(shopId))
        }
    },
    beforeMount() {
        this.cartStore.$subscribe((callback, state) => {
            this.carts = Object.entries(state.carts)
        })
        this.carts = Object.entries(this.cartStore.carts)
        this.navStore.pageLoading = false
    },
    setup() {
        const cartStore = useCartStore()
        const navStore = useNavStore()
        return {
            cartStore,
            navStore
        }
    }
}

</script>