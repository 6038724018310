import { DEBUG } from "@/main";

export const appDayList = [
    { id: "1-sunday", text: "Dimanche" },
    { id: "2-monday", text: "Lundi" },
    { id: "3-tuesday", text: "Mardi" },
    { id: "4-wednesday", text: "Mercredi" },
    { id: "5-thursday", text: "Jeudi" },
    { id: "6-friday", text: "Vendredi" },
    { id: "7-saturday", text: "Samedi" }
]

export function dayFormat(day) {
    const formatDay = appDayList.filter((data) => data.id === day)[0]
    return formatDay.text
}

export function hourFormat(hour) {
    const el = hour.split(':')
    return `${el[0]}h${el[1]}`
}

export function formatPrice(price) {
    const newPrice = price / 100;
    return newPrice.toFixed(2)
}

export function getUrlParamsObject(url) {
    const proxyUrl = new URL(url)
    return new Proxy(new URLSearchParams(proxyUrl.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
}

export const orderStates = [
    { id: "sent", value: "Nouveau" },
    { id: "accepted", value: "Acceptée" },
    { id: "refused", value: "Refusée" },
    { id: "in_progress", value: "En préparation" },
    { id: "canceled", value: "Annulée" },
    { id: "available", value: "Prête" },
    { id: "collected", value: "Récupérée" },
]

/* Renvoi les états possibles en fonction d'un état d'entrée */
export const allowedState = (state) => {
    switch (state) {
        case 'sent': return ['accepted', 'refused']
        case 'accepted': return ['in_progress', 'canceled']
        case 'in_progress': return ['available', 'canceled']
        case 'available': return ['collected', 'canceled']
    }
    return []
}


export const navigate = async function (router, routerOptions) {
    try {
        await router.push(routerOptions);
    }
    catch (err) {
        if (DEBUG) {
            console.error(err);
        }
    }
}


export const checkOpeningState = (horaires) => {
    let currentDateTime = new Date()
    let currentDay = currentDateTime.getDay() + 1
    let todayOpening = horaires.filter((horaire) => {
        if (currentDay == horaire.day.split('-')[0]) {
            let openHour = horaire.open_at
            let closeHour = horaire.close_at

            let shopOpenDateTime = new Date()
            shopOpenDateTime.setHours(openHour.split(':')[0])
            shopOpenDateTime.setMinutes(openHour.split(':')[1])
            shopOpenDateTime.setSeconds(openHour.split(':')[2])

            let shopCloseDateTime = new Date()
            shopCloseDateTime.setHours(closeHour.split(':')[0])
            shopCloseDateTime.setMinutes(closeHour.split(':')[1])
            shopCloseDateTime.setSeconds(closeHour.split(':')[2])

            if (currentDateTime < shopOpenDateTime || currentDateTime >= shopCloseDateTime) {
                return false
            } else {
                return true
            }
        } else {
            return false
        }
    })

    if (todayOpening.length != 0) {
        return true
    } else {
        return false
    }
}

export const shuffleArray = function (table) {
    let first,
        second,
        temp,
        count = table.length;
    for (let i = 0; i < 10; i++) {
        first = Math.floor(Math.random() * count);
        second = Math.floor(Math.random() * count);
        temp = table[first];
        table[first] = table[second];
        table[second] = temp;
    }
    return table;
}

export const setCookie = function (name, value, days) {
    let expires = ''
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const getCookie = function (name) {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}