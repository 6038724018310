<template>
  <v-row v-if="categories">
    <v-col cols="12" class="pb-0"><span class="section-title">Catégories</span></v-col>
    <v-container v-if="loading" style="height:300px">
        <v-row class="fill-height text-center" align-content="center" justify="center">
            <v-col cols=12>
                <v-progress-circular
                    color="primary"
                    indeterminate
                    :size="70"
                ></v-progress-circular>
            </v-col>
        </v-row>
    </v-container>
    <v-col cols="12" v-if="!loading">
      <v-row v-if="categories.length > 0">
        <v-col cols="4" sm="3" lg="2" xl="1" v-for="category in categories" :key="category.id">
          <button type="button" class="category-card" @click.prevent="navigate(`category`, category.id)">
            <img v-if="category.photo.square" :src="category.photo.square" alt class="category-card-image placeholder-glow"/>
            <span class="category-card-title">{{ category.name }}</span>
          </button>
        </v-col>
      </v-row>
      <div class="product-card no-btn" v-else>
        <div class="product-card-content text-center">
            <div class="product-card-text">
            <div class="mb-2">
                <v-icon color="primary" x-large>mdi-bookmark-off</v-icon>
            </div>
            <strong>Aucune catégorie</strong>
            </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<style lang="scss"></style>

<script>
import { useShopStore } from "@/stores/shop-store";
import { navigate } from "@/utils";
import { useNavStore } from "@/stores/navigation-store";

export default {
  name: "ShopCategories",
  data: () => ({
    selectedMenu: "home",
    categories: null,
    loading: true,
  }),
  methods : {
    async navigate(routerName, categoryId) {
      if (this.navStore.getSelectedMenu != routerName) {
        this.navStore.pageLoading = true
        this.navStore.setPreviousRoute(this.$route)
        navigate(this.$router, {name: routerName, params: {id: categoryId}}).then(() => {
          this.navStore.pageLoading = false
        })
      }
    }
  },
  beforeMount() {
    this.selectedMenu = this.navStore.getSelectedMenu;
    this.navStore.$subscribe((callback, state) => {
      this.selectedMenu = state.selectedMenu
    })
    this.shopStore.$subscribe((callback, state) => {
      this.categories = state.categories
    })
    this.categories = this.shopStore.categories
    this.loading = false;

  },
  setup() {
    const shopStore = useShopStore()
    const navStore = useNavStore()
    return {
      shopStore,
      navStore,
    };
  }
};
</script>