<template>
    <v-row>
        <v-col cols="12">
            <SearchShopForm @refresh="loadResults" @clear="clearResults" />
        </v-col>
        <v-col cols="12">
            <v-container v-if="loading" style="height:300px">
                <v-row class="fill-height text-center" align-content="center" justify="center">
                    <v-col cols=12>
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            :size="70"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-container>
            <v-row v-if="!loading && shops.length">
                <v-col cols="12" md="6" lg="4" xl="3" v-for="shop in shops" :key="'shop-'+shop.id">
                    <ShopCard :shop="shop" :longAdress="false" />
                </v-col>
            </v-row>
            <v-row v-if="!loading && shops.length == 0 && keywords" class="text-center"><v-col>Aucun résultats.</v-col></v-row>
        </v-col>
    </v-row>
</template>

<style lang="scss"></style>

<script>

import SearchShopForm from "@/views/home/components/SearchShopForm.vue";
import ShopCard from "@/views/shop/components/ShopCard.vue";
import { useShopStore } from "@/stores/shop-store";
import { ShopService } from "@/services/shop-services";
import { shuffleArray } from "@/utils";
import { useNavStore } from "@/stores/navigation-store";

const shopService = new ShopService();

export default {
    name: "SearchPage",
    components: {
        ShopCard,
        SearchShopForm,
    },
    data: () => ({
        loading: true,
        shops: [],
        keywords: null,
    }),
    methods: {
        async getSearchShopsResults(keywords) {
            const res = await shopService.searchShops(keywords)
            if (!res.error) {
                this.shops = res
            } else {
                console.log("Oops impossible de charger les résultats")
            }
        },
        loadResults(keywords) {
            this.loading = true
            this.keywords = keywords
            this.getSearchShopsResults(keywords).then(() => {
                this.loading = false
            })
        },
        clearResults() {
            this.keywords = null
            this.shops = shuffleArray(this.shopStore.shopList);
        }
    },
    beforeMount() {
        this.navStore.setPageTitle('Explorer');
        this.keywords = this.$route.query.q
        if (this.keywords) {
            this.loadResults(this.keywords)
        } else {
            this.shopStore.$subscribe(async (callback, state) => {
                this.shops = shuffleArray(state.shopList);
            });
            this.shops = shuffleArray(this.shopStore.shopList);
            this.loading = false
        }
        this.navStore.pageLoading = false
    },
    setup() {
        const shopStore = useShopStore()
        const navStore = useNavStore()
        return {
            shopStore,
            navStore,
        };
    },
};
</script>