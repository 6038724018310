<template>
<v-dialog
    max-width="500px"
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
    <v-btn
        color="danger"
        plain
        small
        v-bind="attrs"
        v-on="on"
    >
        <v-icon>delete_outline</v-icon>
        <span class="sr-only">Supprimer</span>
    </v-btn>
    </template>
    <v-card>
    <v-card-title>
        Êtes-vous sûr de vouloir supprimer le mail "{{ email.email }}" ?
    </v-card-title>
    <v-card-text>Vous ne pourrez plus vous connecter à votre espace commerçant en utilisant ce mail</v-card-text>
    <v-card-actions>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
            Non, revenir en arrière
        </v-btn>
        
        <v-btn
            color="danger"
            class="ml-auto"
            text
            :loading="loading"
            @click="remove()"
        >
            Supprimer
        </v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { AuthenticationService } from "@/services/auth-services";
import { useAuthUser } from "@/stores/auth-store";

const authService = new AuthenticationService()

export default {
    name: "ProfileEmailDel",
    props: {
        email: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        loading: false,
        dialog: false
    }),
    methods: {
        async remove() {
            this.loading = true
            try {
                await authService.delEmail(this.email.email, this.authUser.getToken)
                this.authUser.emails.results = this.authUser.emails.results.filter((mail) => mail.email != this.email.email)
                this.authUser.emails.count = this.authUser.emails.count - 1
                this.$toast.success('L\'enregistrement a été supprimé')
                this.dialog = false
            } catch (err) {
                this.$toast.error('La suppression de l\'enregistrement a échoué. Si le problème persiste, contacter un administrateur')
            } finally {
                this.loading = false
            }
        }
    },
    setup() {
        const authUser = useAuthUser();
        return {
            authUser,
        };
    },
}
</script>