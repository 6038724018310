<template>
  <footer id="footer">
    <div class="container py-0 d-none d-lg-block">
      <p>
        Click'n'Collect
        <a
          href="https://www.ville-sainte-anne.fr"
          title="Ouvre une nouvelle fenêtre"
          target="_blank"
        >Ville de Sainte-Anne</a>
        | Propulsé par
        <a
          href="https://www.i-administration.fr"
          title="Ouvre une nouvelle fenêtre"
          target="_blank"
        >
          <img src="@/assets/iadministration.jpg" class="logo-footer-text" alt />
          I-Administration
        </a>
      </p>
    </div>
    <div class="container mobile-menu-container pa-0 d-block d-lg-none" v-if="$route.name != 'login'">
      <ul class="app-menu">
        <li>
          <a
            href="/" class="app-nav-item" :class="{ active: isActive('home') }"
            @click.prevent="navigate('home')"
          >
            <v-icon class="app-nav-icon">home</v-icon>
            <span class="app-nav-text sr-only">Accueil</span>
          </a>
        </li>
        <li>
          <a
            href="/search" class="app-nav-item" :class="{ active: isActive('search') }"
            @click.prevent="navigate('search')"
          >
            <v-icon class="app-nav-icon">mdi-magnify</v-icon>
            <span class="app-nav-text sr-only">Rechercher</span>
          </a>
        </li>
        <li>
          <a
            href="/map" class="app-nav-item" :class="{ active: isActive('map') }"
            @click.prevent="navigate('map')"
          >
            <v-icon class="app-nav-icon">mdi-map-marker-multiple</v-icon>
            <span class="app-nav-text sr-only">Carte</span>
          </a>
        </li>
        <li>
          <a
            href="/favorites" class="app-nav-item" :class="{ active: isActive('favorites') }"
            @click.prevent="navigate('favorites')"
          >
            <v-icon class="app-nav-icon">mdi-heart</v-icon>
            <span class="app-nav-text sr-only">Favoris</span>
          </a>
        </li>
        <li>
          <a
            href="/user/profile" class="app-nav-item account"
            :class="{ active: isActive('profile') }"
            @click.prevent="navigate('profile')"
          >
            <v-icon class="app-nav-icon">mdi-account</v-icon>
            <span class="app-nav-text sr-only">Paramètres</span>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss"></style>

<script>

import { useAuthUser } from "@/stores/auth-store";
import { useNavStore } from "@/stores/navigation-store";
import { navigate } from "@/utils";


export default {
  name: "Footer",
  data: () => ({
    selectedMenu: "home",
  }),
  methods: {
    isActive(routerName) {
      return routerName == this.selectedMenu ? true : false;
    },
    navigate(routerName) {
      if (this.$router.history.current.name != routerName) {
        this.navStore.pageLoading = true
        navigate(this.$router, { name: routerName })
      }
    },
  },
  beforeMount() {
    this.selectedMenu = this.navStore.getSelectedMenu;
    this.navStore.$subscribe((callback, state) => {
      this.selectedMenu = state.selectedMenu;
    });
  },
  setup() {
    const authUser = useAuthUser();
    const navStore = useNavStore();
    return {
      authUser,
      navStore,
    };
  },
};
</script>