<template>
    <div class="shop-page">
        <ShopHeader />
        <Products />
    </div>
</template>

<style lang="scss"></style>

<script>
import Products from "@/views/products/components/Products.vue";
import ShopHeader from "./components/ShopHeader.vue";

export default {
    name: "ShopPage",
    components: { Products, ShopHeader},
};
</script>
