<template>
    <div class="product-detail-page">
        <ProductHeader :product="product" :productCategory="productCategory" />
        <ProductOrderButtons :product="product" :productCategory="productCategory" />
    </div>
</template>

<script>
import ProductOrderButtons from "@/views/products/components/ProductOrderButtons.vue";
import ProductHeader from "./components/ProductHeader.vue";
import { ProductService } from "@/services/product-services";
import { ShopService } from "@/services/shop-services";
import { useShopStore } from "@/stores/shop-store";
import { useNavStore } from "@/stores/navigation-store";

const productService = new ProductService();
const shopService = new ShopService();


export default {
    name: "ProductPage",
    components: { ProductOrderButtons, ProductHeader },
    data() {
        return {
            product: {},
            productCategory: null
        }
    },
    methods: {
        async getProduct() {
            const product = await productService.getProduct(this.productId)
            if (product) {
                return product;
            }
            return [];
        },
        async getCategory() {
            const productCategories = await shopService.getProductCategories(this.product.store);
            if (productCategories) {
                return productCategories.filter(cat => cat.id == this.product.category)[0];
            }
            return [];
        },
    },
    async beforeMount() {
        this.navStore.pageLoading = true
        this.getProduct().then((product) => {
            this.product = product
            this.navStore.setPageTitle(this.product.name)
            this.getCategory().then((category) => {
                this.productCategory = category
            })
            this.navStore.pageLoading = false
        })
    },
    setup() {
        const shopStore = useShopStore()
        const navStore = useNavStore()
        return {
            shopStore,
            navStore,
        }
    },
    computed: {
        productId() {
            return this.$route.params.id
        }
    }
}
</script>