<template>
  <div class="home-page">
    <ShopCategories />
    <Shops v-if="shops.length > 0" />
  </div>
</template>

<style lang="scss"></style>

<script>
import ShopCategories from "@/views/shop/components/ShopCategories.vue";
import Shops from "@/views/shop/components/Shops.vue";
import { useShopStore } from "@/stores/shop-store";
import { useNavStore } from "@/stores/navigation-store";


export default {
  name: "HomePage",
  components: { ShopCategories,Shops },
  data: () => {
    return {
      shops: [],
    }
  },
  computed: {
    limit() {
      switch (this.$vuetify.breakpoint.name) {
        case 'md': return 2
        case 'sm': return 2
        case 'xs': return 1
      }
      return 3
    },
  },
  beforeMount() {
    this.shopStore.$subscribe((callback, state) => {
      this.shops = state.shopList
    })
    this.shops = this.shopStore.shopList
    this.navStore.pageLoading = false
  },
  setup() {
    const shopStore = useShopStore()
    const navStore = useNavStore()
    return {
      shopStore,
      navStore
    }
  }
};
</script>
