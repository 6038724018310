<template>
    <div class="shop-like-btn">
        <v-btn :fab="fab" :icon="icon" :small="small" :x-small="xSmall" :dark="dark" :color="getColor()" @click.stop="toggleFav">
            <v-icon>mdi-heart</v-icon>
        </v-btn>
    </div>
</template>


<script>
import { useFavStore } from "@/stores/favorites-store"

export default {
    name: 'ShopLikeButton',
    props: {
        shopId: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            default: 'grey'
        },
        icon: {
            default: false
        },
        fab: {
            default: false
        },
        small: {
            default: false
        },
        xSmall: {
            default: false
        },
        dark: {
            default: false
        },
    },
    methods: {
        isFavorite() {
            let index = this.favStore.favorites.findIndex(id => id == this.shopId)
            return  index > -1;
        },
        getColor() {
            return this.isFavorite() ? this.color : 'grey';
        },
        toggleFav() {
            if (this.isFavorite()) {
                let removed = this.favStore.removeFav(this.shopId)
                if (removed) {
                    console.log('Enlevé des favoris')
                } else {
                    this.$toast.error("Impossible de l'enlever des favoris")
                }
            } else {
                let added = this.favStore.addFav(this.shopId)
                if (added) {
                    console.log('Ajouté aux favoris')
                } else {
                    this.$toast.error("Impossible de l'ajouté dans les favoris")
                }
            }
        }
    },
    setup() {
        const favStore = useFavStore();
        return {
            favStore,
        }
    },
}
</script>