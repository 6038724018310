import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        // primary: '#1976d2',
        // danger: '#ff5252',
        primary: '#03b1bb',
        danger: '#C62828',
        success: '#4caf50',
        warning: '#fb8c00',
      }
    }
  }
});
