<template>
    <v-form @submit.prevent="submit">
        <v-text-field
            v-model="keywords"
            required
            append-icon="mdi-magnify"
            outlined
            clear-icon="mdi-close-circle"
            clearable
            label="Rechercher"
            type="text"
            @click:append="submit"
            @click:clear="clear"
        ></v-text-field>
    </v-form>
</template>

<style lang="scss"></style>

<script>
import { useNavStore } from "@/stores/navigation-store";
import { navigate } from "@/utils";

export default {
    name: "SearchShopForm",
    data: () => ({
        keywords: '',
    }),
    methods: {
        submit() {
            let routerOptions = {name: "search", query: {q: this.form.keywords}}
            if (this.selectedMenu != routerOptions.name) {
                this.navStore.pageLoading = true;
                this.navStore.setPreviousRoute(this.$route)
                navigate(this.$router, routerOptions).then(() => {
                    this.navStore.pageLoading = false;
                })
            } else {
                this.$emit('refresh', this.form.keywords)
                const url = new URL(window.location);
                url.searchParams.set("q", this.form.keywords);
                window.history.pushState({}, "", url);
            }
        },
        clear() {
            if (this.selectedMenu == "search") {
                this.$emit('clear')
                const url = new URL(window.location);
                url.searchParams.set("q", "");
                window.history.pushState({}, "", url);
            }
        },
    },
    beforeMount() {
        this.keywords = this.$route.query.q
        this.selectedMenu = this.navStore.getSelectedMenu;
        this.navStore.$subscribe((callback, state) => {
            this.selectedMenu = state.selectedMenu;
        });
    },
    setup () {
        const navStore = useNavStore()
        return {
            navStore,
        }
    },
    computed: {
        form() {
            return {
                keywords: this.keywords
            }
        },
    }
};
</script>
