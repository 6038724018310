<template>
    <div class="profile-password">
        <div class="title">Nouveau mot de passe</div>
        <v-form ref="userForm" lazy-validation>
            <div  @keyup.enter="save">
                <v-text-field
                    v-model="lastPassword"
                    label="Ancien mot de passe*"
                    filled
                    type="password"
                    :error-messages="errors.password"
                ></v-text-field>
                <v-text-field
                    v-model="newPassword"
                    label="Nouveau mot de passe*"
                    filled
                    :error-messages="errors.new_password1"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-text-field
                    v-if="!showPassword"
                    v-model="confirmPassword"
                    label="Confirmer le mot de passe*"
                    filled
                    type="password"
                    :error-messages="errors.new_password2"
                ></v-text-field>
            </div>
            <v-btn
                color="primary"
                :loading="loading"
                @click.prevent="save()"
            >
                Enregistrer
            </v-btn>
        </v-form>
    </div>  
</template>

<script>
import { AuthenticationService } from "@/services/auth-services"
import { useAuthUser } from "@/stores/auth-store"

const authService = new AuthenticationService()

export default {
    name: 'ProfilePassword',
    data: () => ({
        loading: false,
        errors: {},
        showPassword: false,
        lastPassword: '',
        newPassword: '',
        confirmPassword: '',
    }),
    methods: {
        clear() {
            this.lastPassword = ''
            this.confirmPassword = ''
            this.newPassword = ''
        },
        async save() {
            this.errors = {}
            if(this.$refs.userForm.validate()) {
                this.loading = true
                try {
                    // Validation de l'ancien mot de passe
                    const validEmail = this.authUser.emails.results.filter((mail) => mail.primary === true)[0]
                    await authService.logIn(validEmail.email, this.lastPassword)
                } catch(err) {
                    this.errors = err
                    if(err.non_field_errors) {
                        this.errors.password = 'Mot de passe incorrect'
                    } else {
                        this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
                    }
                    return false
                } finally {
                    this.loading = false
                }
                this.loading = true
                try {
                    // Changement de mot de passe
                    let confPass = this.showPassword ? this.newPassword : this.confirmPassword
                    await authService.changePassword(this.newPassword, confPass, this.authUser.getToken)
                    this.$toast.success('Votre mot de passe a bien été modifié')
                    authService.logOut(this.authUser.getToken).then(() => {}, ()=> {}).finally(() => {
                        this.authUser.clearAuth()
                        this.$router.push({
                            name: "login",
                        })
                    })
                    this.$toast.warning('Veuillez vous reconnecter en utilisant votre nouveau mot de passe')
                    this.clear()
                } catch(err) {
                    this.errors = err
                    if(err.non_field_errors) {
                        this.errors.password = 'Mot de passe incorrect'
                    } else {
                        this.$toast.error('Votre formulaire contient des erreurs. Veuillez vérifier votre saisie')
                    }
                    return false
                } finally {
                    this.showPassword = false
                    this.loading = false
                }
            }
        }
    },
    setup() {
        const authUser = useAuthUser()
        
        return {
            authUser,
        }
    }
}
</script>