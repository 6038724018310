<template>
    <div class="orders">
        <div v-if="loading" style="height:300px">
            <v-row class="fill-height text-center" align-content="center" justify="center">
                <v-col cols=12>
                    <v-progress-circular color="primary" indeterminate :size="70"></v-progress-circular>
                </v-col>
            </v-row>
        </div>
        <OrderList v-if="!loading && orders.length" :orders="orders" />
        <div class="product-card no-btn" v-if="!loading && !orders.length">
            <div class="product-card-content text-center">
                <div class="product-card-text">
                    <div class="mb-2">
                        <v-icon color="primary" x-large>dvr</v-icon>
                    </div>
                    <strong>Aucun commande.</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DEBUG } from "@/main";
import { OrderService } from "@/services/order-services";
import { useAuthUser } from "@/stores/auth-store";
import { useNavStore } from "@/stores/navigation-store";
import { useOrderStore } from "@/stores/order-store";
import { useShopStore } from "@/stores/shop-store";
import { getUrlParamsObject, orderStates } from "@/utils";
import OrderList from "@/views/orders/components/OrderList.vue";

const orderService = new OrderService()

export default {
    name: "Orders",
    props: {
        headingLevel: {
            type: Number,
            default: 1
        },
        title: {
            type: String,
            default: "Mes commandes"
        },
        limit: Number,
        filterOptions: {
            type: Object,
            default: () => ({
                category: null,
                ordering: "-updated",
                page: 1
            })
        },
        showFilter: {
            type: Boolean,
            default: false
        },
        modeWidget: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        loading: true,
        orders: [],
        orderCounter: 0,
        getOrderOptions: {},
        nextPage: false,
        orderOrdering: [
            { id: '-updated', name: 'Par date de modification' },
            { id: '-created', name: 'Par date d\'ajout' },
            { id: 'total_ht', name: 'Par montant croissant' },
            { id: '-total_ht', name: 'Par montant décroissant' },
        ],
        orderStates: orderStates
    }),
    methods: {
        async navigate(routerName) {
            this.navStore.pageLoading = true;
            this.navStore.setSelectedMenu(routerName);
            try {
                await this.$router.push({
                    name: routerName,
                });
            }
            catch (err) {
                console.error(err);
            }
            finally {
                this.navStore.pageLoading = false;
            }
        },
        async getOrders() {
            try {
                const orders = await orderService.getOrders(this.authUser.getToken, this.getOrderOptions);
                if (this.limit) {
                    return orders.results.slice(0, this.limit);
                }
                this.orderCounter = orders.count;
                if (orders.next) {
                    const params = getUrlParamsObject(orders.next);
                    this.getProductOptions.page = params.page;
                    this.nextPage = true;
                }
                else {
                    this.nextPage = false;
                }
                return orders.results;
            }
            catch (error) {
                console.error(error);
                this.$toast.error("Une erreur s'est produite lors de la récupération de la liste des commandes");
            }
            finally {
                this.loading = false;
            }
            return [];
        },
        refreshOrders() {
            this.getOrderOptions.page = 1;
            this.getOrders().then(orders => this.orders = orders);
        },
        infiniteScroll(entries) {
            if (entries[0].isIntersecting && this.nextPage) {
                this.getOrders().then(orders => orders.forEach((o) => this.orders.push(o)));
            }
        },
        refresherInterval() {
            this.getOrders().then(orders => {
                const changes = orders.filter(val => {
                    const test = this.orders.filter(o => o.reference == val.reference)
                    return test.length == 0
                })
                if (changes.length > 0) {
                    changes.forEach(o => {
                        this.orders.unshift(o)
                    })
                    this.$toast.info('Vous avez reçu une nouvelle commande')
                }
            });
        },
    },
    async beforeMount() {
        this.navStore.setPageTitle('Mes commandes')
        this.getOrderOptions = this.filterOptions
        try {
            await this.refreshOrders();
        }
        catch (err) {
            if (DEBUG) {
                console.error(err);
            }
        }
        finally {
            this.loading = false;
        }
    },
    mounted() {
        this.orderStore.setRefresherInterval(() => {
            this.refresherInterval()
        }, 3 * 60 * 1000) /* toute les 3 mins */
    },
    beforeUnmount() {
        this.orderStore.stopRefresherInterval()
    },
    setup() {
        const authUser = useAuthUser();
        const shopStore = useShopStore();
        const orderStore = useOrderStore();
        const navStore = useNavStore()
        return {
            authUser,
            shopStore,
            orderStore,
            navStore,
        };
    },
    components: { OrderList }
}
</script>