<template>
  <div class="login-form-component">
    <h2 class="login-title text-center">Click N'Collect</h2>
    <h3 class="login-subtitle text-center">Ville de Sainte-Anne</h3>

    <div v-if="signedResponse" class="text-center mt-5">
      <p class="text-h6 mb-1" color="success darken-2">Confirmez votre addresse email</p>
      <p class="login-text-info">Un email de confirmation a été envoyé à : <strong>{{ email }}</strong><br>
        Consulter votre boîte de réception et cliquer sur le lien de confirmation pour vous finaliser votre inscription.
      </p>
      <a class="text-center" @click="signedResponse=false">Me connecter</a>
    </div>    

    <v-form v-if="!signedResponse && selectedForm == 'login'" lazy-validation ref="logginForm">
      <div @keyup.enter.prevent="validate">
        <p class="text-h6 text-center primary--text mb-2">Connexion</p>
        <p class="login-text-info text-center">Pour accéder à votre compte veuillez vous connecter</p>
        <v-text-field
          v-model="email"
          label="Email *"
          type="email"
          :error-messages="errors.email"
          required
        ></v-text-field>

        <v-text-field
          v-model="password"
          label="Mot de passe *"
          required
          :error-messages="errors.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <div class="d-flex flex-column align-items-center">
          <v-btn block color="primary" class="mb-3 myValidBtn" @click.prevent="validate" :loading="loading">Me connecter</v-btn>
          <a class="text-center" @click="toggleForm('forgot')">Mot de passe oublié ?</a>
          <a class="text-center" @click="toggleForm('signup')">Pas encore de compte ?</a>
        </div>
      </div>
    </v-form>

    <v-form v-if="!signedResponse && selectedForm == 'forgot'" lazy-validation ref="passwordForgottenForm">
      <div @keyup.enter.prevent="sendEmail">
        <p class="text-h6 text-center primary--text mb-2">Mot de passe oublié ?</p>
        <p class="login-text-info text-center">Veuilez saisir votre e-mail et vous recevrer toutes les informations nécessaires pour générer un nouveau mot de passe.</p>
        <v-text-field
          label="Entrez votre email *"
          type="email"
          v-model="emailForgotten"
          :error-messages="forgotErrors.email"
          required
        ></v-text-field>
        <v-text-field class="sr-only"></v-text-field>
        <div class="d-flex flex-column align-items-center">
          <v-btn block color="primary" class="mb-3" @click.prevent="sendEmail" :loading="loading">Envoyez</v-btn>
          <a class="text-center" @click="toggleForm('login')">Me connecter</a>
          <a class="text-center" @click="toggleForm('signup')">Pas encore de compte ?</a>
        </div>
      </div>
    </v-form>

    <v-form v-if="!signedResponse && selectedForm == 'signup'" lazy-validation ref="signUpForm">
      <div @keyup.enter.prevent="signUp">
        <p class="text-h6 text-center primary--text mb-2">Inscription</p>
        <v-text-field
          v-model="username"
          label="Nom d'utilisateur *"
          type="text"
          :error-messages="signUpErrors.username"
          required
        ></v-text-field>

        <v-text-field
          v-model="email"
          label="Email *"
          type="email"
          :error-messages="signUpErrors.email"
          required
        ></v-text-field>

        <v-text-field
          v-model="password1"
          label="Mot de passe *"
          required
          :error-messages="signUpErrors.password1"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        ></v-text-field>

        <v-text-field
          v-model="password2"
          label="Confirmer le mot de passe *"
          required
          :error-messages="signUpErrors.password2"
          :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showConfirm ? 'text' : 'password'"
          @click:append="showConfirm = !showConfirm"
        ></v-text-field>

        <div class="d-flex flex-column align-items-center">
          <v-btn block color="primary" class="mb-3" @click.prevent="signUp" :loading="loading">M'inscrire</v-btn>
          <a class="text-center" @click="toggleForm('forgot')">Mot de passe oublié ?</a>
          <a class="text-center" @click="toggleForm('login')">Déjà inscrit ?</a>
        </div>
      </div>
    </v-form>

    <div class="d-flex justify-center py-6">
      <v-btn depressed small fab class="text-center mx-2" @click="goBack()">
        <v-icon color="blue-grey darken-1">mdi-arrow-left</v-icon>
        <span class="sr-only">Retour</span>
      </v-btn>
      <v-btn depressed small fab class="text-center mx-2" @click="navigate('home')">
      <v-icon color="blue-grey darken-1">home</v-icon>
      <span class="sr-only">Accueil</span>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { AuthenticationService } from "@/services/auth-services";
import { useAuthUser } from "@/stores/auth-store";
import { useNavStore } from "@/stores/navigation-store";
import { navigate } from "@/utils";

export default {
  name: "LoginForm",
  data: () => ({
    emailForgotten: "",
    showPassword: false,
    showConfirm: false,
    password: "",
    password1: "",
    password2: "",
    username: "",
    loading: false,
    selectedForm: 'login',
    email: "",
    errors: {},
    forgotErrors: {},
    signUpErrors: {},
    signedResponse: false,
  }),
  methods: {
    async validate() {
      this.errors = {}
      if (this.$refs.logginForm.validate()) {
        this.loading = true
        try {
          const res = await new AuthenticationService().logIn(
            this.email,
            this.password
          );
          this.authUser.setToken(res.key);
          this.navStore.pageLoading = true
          this.$toast.success("Bienvenue sur Click'N'Collect");
          await this.$router.push({
            name: 'home'
          });
          this.navStore.selectedMenu = 'home'
          this.navStore.pageLoading = false
        } catch (err) {
          this.errors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    async sendEmail() {
      this.forgotErrors = {}
      if (this.$refs.passwordForgottenForm.validate()) {
        this.loading = true;
        try {
          await new AuthenticationService().sendEmail(this.emailForgotten);
          this.$toast.info("Un email vous a été envoyé");
          this.toggleForm('login')
          this.emailForgotten = ''
        } catch (err) {
          this.forgotErrors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          }
        } finally {
          this.loading = false
        }
      }
    },
    async signUp() {
      this.signUpErrors = {}
      const valuesOk = this.username && this.password1 == this.password2
      if (valuesOk && this.$refs.signUpForm.validate()) {
        this.loading = true
        try {
          await new AuthenticationService().signUp(this.email,this.password1, this.password2, this.username)
          this.$toast.info("Un email vous a été envoyé")
          this.signedResponse = true
          this.toggleForm('login')
          this.username = ''
          this.password2 = ''
        } catch (err) {
          console.log(err)
          this.signUpErrors = err
          if (err.non_field_errors) {
            for (const message of err.non_field_errors) {
              this.$toast.error(message)
            }
          }
        } finally {
          this.loading = false
        }
      } else {
        if (!this.username) {
          this.signUpErrors.username = 'Ce champ ne peut être vide'
        }
        if (this.password1 != this.password2) {
          this.signUpErrors.password2 = 'Le mot de passe doit être identique'
        }
      }
    },
    toggleForm(form) {
      this.selectedForm = form
    },
    goBack() {
      this.$router.go(-1)
    },
    navigate(routerName) {
        this.navStore.pageLoading = true
        navigate(this.$router, { name: routerName })
    },
  },
  setup() {
    const authUser = useAuthUser();
    const navStore = useNavStore()
    return { authUser, navStore };
  },
};
</script>

