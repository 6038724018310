import { VueHttp } from "@/vue-http";

export class OrderService {
    async getOrders(token, options = null) {
        try {
            const mytoken = `token ${token}`;
            const orders = await VueHttp.get(`order/`, {
                headers: {
                    'Authorization': mytoken,
                },
                params: options
            });
            return orders.data;
        } catch (error) {
            throw error.response.data;
        }
    }

    async createOrder(shopId, userId, orderLines, token) {
        try {
            const mytoken = `token ${token}`;
            const myOrder = {
                order: {
                    store: shopId,
                    customer: userId,
                    state: "waiting",
                    lines: []
                },
                lines: orderLines
            }
            const res = await VueHttp.post(`order/add/`, myOrder, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data;
        } catch (error) {
            throw error.response.data;
        }
    }

    async addOrderLine(orderRef, productId, quantity, token) {
        try {
            const mytoken = `token ${token}`;
            let formData = new FormData();
            formData.append('product', productId)
            formData.append('quantity', quantity)
            const res = await VueHttp.post(`order/${orderRef}/line/`, formData, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data;
        } catch (error) {
            throw error.response.data;
        }
    }


    async updateStateOrder(orderRef, state, token) {
        try {
            const mytoken = `token ${token}`;
            let option = {'state': state}
            const res = await VueHttp.put(`order/${orderRef}/`, option, {
                headers: {
                    'Authorization': mytoken,
                }
            });
            return res.data;
        } catch (error) {
            throw error.response.data;
        }
    }


    // async update(orderRef, newState, token) {
    //     try {
    //         const mytoken = `token ${token}`;

    //         const res = await VueHttp.patch(`me-order/${orderRef}/change/`,
    //             { state: newState }, {
    //             headers: {
    //                 'Authorization': mytoken,
    //             }
    //         });
    //         return res.data
    //     } catch (error) {
    //         throw error.response.data
    //     }
    // }

    // async print(orderRef, token) {
    //     try {
    //         const mytoken = `token ${token}`;

    //         const res = await VueHttp.get(`order/${orderRef}/pdf/`, {
    //             headers: {
    //                 'Authorization': mytoken,
    //             }
    //         });
    //         return res.data
    //     } catch (error) {
    //         throw error.response.data
    //     }
    // }
}

