import { DEBUG } from "@/main";
import { VueHttp } from "@/vue-http";

export class ProductService {

  async getProduct(id) {
    try {
      const res = await VueHttp.get(`product/${id}/`);
      return res.data;
    } catch (error) {
      if (DEBUG) {
        console.error(error)
      }
      this.$toast.error("Une erreur s'est produite lors du chargement du produit");
    }
  }
}