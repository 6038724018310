import { defineStore } from 'pinia'
import CryptoJS from 'crypto-js';
import { getCookie, setCookie } from '@/utils';
import { DEBUG } from '@/main';

const SECRET_KEY = 'hoo9eiBoh9Kaetaeng2ii1iethoh7queu1Ekahy1oY';

export const useAuthUser = defineStore('authUser', {

  state: () => {
    return {
      token: '',
      user: {},
      emails: []
    }
  },
  actions: {
    setToken(token) {
      this.token = token;
      const encryptedToken = CryptoJS.AES.encrypt(this.token, SECRET_KEY).toString();
      setCookie('clickncollect-token',JSON.stringify(encryptedToken), .25)
    },
    clearAuth() {
      this.token = ''
      this.user = {}
      this.emails = []
      setCookie('clickncollect-token','', -10000)
    },
  },
  getters: {
    getToken: (state) => {
      if (!state.token.length > 0) {
        try {
          const encryptedToken = JSON.parse(getCookie('clickncollect-token'));
          const token = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
          state.token = token.toString(CryptoJS.enc.Utf8)
        } catch (error) {
          if(DEBUG) {
            console.error(error)
          }
          return ''
        }
      }
      return state.token;
    },
  }
})