import VueRouter from 'vue-router'

import { AuthenticationService } from "@/services/auth-services";
import { useAuthUser } from '@/stores/auth-store';
import LoginPage from '@/views/login/LoginPage.vue'
import PrivateView from '@/views/PrivateView.vue'
import PublicView from '@/views/PublicView.vue'
import HomePage from '@/views/home/HomePage.vue'
import ProfilePage from '@/views/profile/ProfilePage';
import OrdersPage from '@/views/orders/OrdersPage';
import CategoryPage from '@/views/shop/CategoryPage';
import ShopPage from '@/views/shop/ShopPage';
import SearchPage from '@/views/home/SearchPage';
import ProductPage from '@/views/products/ProductPage.vue';
import CartPage from '@/views/cart/CartPage.vue';
import FavoritesPage from '@/views/shop/FavoritesPage';
import MapPage from '@/views/shop/MapPage';
import { useNavStore } from '@/stores/navigation-store';


const authService = new AuthenticationService();

const routes = [
  {
    path: '/login/',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/',
    component: PublicView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomePage,
      },
      {
        path: 'shop/:id',
        name: 'shop',
        component: ShopPage,
      },
      {
        path: 'product/:id',
        name: 'product',
        component: ProductPage,
      },
      {
        path: 'category/:id',
        name: 'category',
        component: CategoryPage,
      },
      {
        path: 'search/',
        name: 'search',
        component: SearchPage,
      },
      {
        path: 'map/',
        name: 'map',
        component: MapPage,
      },
      {
        path: 'cart/',
        name: 'cart',
        component: CartPage,
      },
      {
        path: 'favoris/',
        name: 'favorites',
        component: FavoritesPage,
      },    
    ]
  },
  {
    path: '/user/',
    name:'user',
    component: PrivateView,
    children: [
      {
        path: 'profile/',
        name: 'profile',
        component: ProfilePage,
      },
      {
        path: 'orders/',
        name: 'orders',
        component: OrdersPage,
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const navStore = useNavStore()
  if (to.name != 'login') {
    const fromRoute = {}
    for (const [key, value] of Object.entries(from)) {
      if (!['hash','matched'].includes(key)) {
        fromRoute[key] = value
      }
    }
    navStore.setPreviousRoute(fromRoute)
    const onglets = ['home','search','map','favorites','profile']
    if (onglets.includes(to.name)) {
      navStore.setSelectedMenu(to.name)
      navStore.addHistory(to.name,fromRoute)
    } else {
      navStore.addHistory('home',fromRoute)
    }
    if (to.fullPath.includes('/user/')) {
      if (useAuthUser().getToken != '') {
        try {
          const resp = await authService.checkUserIsLogged(useAuthUser().getToken)
          if (resp.is_logged) {
            next()
          }
        } catch (error) {
          next({ name: 'login' })
        }
      } else {
        next({ name: 'login' })
      }
    } else {
      next()
    }
  } else {
    try {
      const resp = await authService.checkUserIsLogged(useAuthUser().getToken)
      if (resp.is_logged) {
        next({ name: 'home' })
      }
    } catch (error) {
      next()
    }
  }
})

export default router
